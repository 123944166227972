import React from 'react'
import {
  Box,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import RemoveIcon from '@material-ui/icons/Remove'
import typography from '../../../../theme/typography'
import { ISonarOverviewTotalAlarms } from '../../../../Common/Logic/Types'
import theme from '../../../../theme/muiTheme'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const useStyles = makeStyles(() => ({
  //@ts-ignore
  ...theme.customClasses.sonarOverviewVisuals,
  dateLabel: {
    fontSize: typography.fontSize.S,
    textAlign: 'left',
  },
  totalAlarmCountSpan: {
    fontFamily: typography.fontFamily.heading,
    fontSize: '22px',
    fontWeight: 700,
    textAlign: 'left',
  },
  totalAlarmTextSpan: {
    fontFamily: typography.fontFamily.heading,
    fontSize: '22px',
    fontWeight: 500,
    textAlign: 'left',
    marginLeft: '8px',
  },
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  boxContainerLastQuarter: {
    display: 'flex',
    alignItems: 'center',
  },
  chipMargin: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  redChip: {
    backgroundColor: 'rgba(215, 79, 57, 1)',
    border: `2px solid rgba(215, 79, 57, 0.26)`,
    color: `#FFFFFF`,
    outline: `2px solid rgba(215, 79, 57, 0.26)`,
    textShadow: `0.2px 0.2px 0.8px rgba(0, 0, 0, 0.30)`,
    fontFamily: typography.fontFamily.body,
    fontWeight: typography.fontWeight.bold,
  },
  dailyAverageTextSpan: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontFamily: typography.fontFamily.body,
    fontSize: '16px',
  },
  dailyAverageValueSpan: {
    fontFamily: typography.fontFamily.body,
    fontWeight: 'bold',
  },

  lightRedArrow: {
    color: 'rgba(215, 79, 57, 1)',
    fontSize: typography.fontSize.M,
  },
  lightRedChip: {
    backgroundColor: 'rgba(215, 79, 57, 0.12)',
    color: 'rgba(150, 55, 39, 1)',
    fontFamily: typography.fontFamily.body,
    fontSize: typography.fontSize.S,
    marginRight: '8px',
    height: '26px',
  },
  lightGreenArrow: {
    color: 'rgba(59, 141, 114, 1)',
    fontSize: typography.fontSize.M,
  },
  lightGreenChip: {
    backgroundColor: 'rgba(59, 141, 114, 0.12)',
    color: `rgba(41, 98, 79, 1)`,
    fontFamily: typography.fontFamily.body,
    fontSize: typography.fontSize.S,
    marginRight: '8px',
    height: '26px',
  },
  lightNavyArrow: {
    color: 'rgba(37, 66, 112, 1)',
    fontSize: typography.fontSize.M,
  },
  lightNavyChip: {
    backgroundColor: 'rgba(244, 244, 244, 1)',
    color: `rgba(52, 64, 81, 1)`,
    fontFamily: typography.fontFamily.body,
    fontSize: typography.fontSize.S,
    marginRight: '8px',
    height: '26px',
  },
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '84px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    whiteSpace: 'nowrap',
  },
  gridItem: {
    marginBottom: '8px',
  },
  gridContainer: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}))

const OverviewTotalAlarms = ({
  totalAlarmsCount,
  newAlarmsLast24HoursCount,
  lastMonthDailyAverage,
  lastMonthDailyAverageChangePercent,
  lastQuarterDailyAverage,
  lastQuarterDailyAverageChangePercent,
}: ISonarOverviewTotalAlarms) => {
  const classes: ClassNameMap<string> = useStyles()

  const renderDateLabel = () => {
    const currentDate = new Date()

    // Format the date in the long string format of Month Day, Year
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
      .format(currentDate)
      .toUpperCase()

    const dateLabel = `TODAY | ${formattedDate}`

    return <Typography className={classes.dateLabel}>{dateLabel}</Typography>
  }

  const renderTotalAlarmAndChip = () => {
    const formattedTotalAlarmsCount = totalAlarmsCount.toLocaleString()
    return (
      <Box className={classes.boxContainer}>
        <Typography>
          <span className={classes.totalAlarmCountSpan}>
            {formattedTotalAlarmsCount}
          </span>
          <span className={classes.totalAlarmTextSpan}>Total Alarms</span>
        </Typography>
        {newAlarmsLast24HoursCount > 0 && (
          <Chip
            className={classes.redChip}
            style={{ marginLeft: '8px' }}
            label={`${newAlarmsLast24HoursCount} New (Last 24 Hrs)`}
          />
        )}
      </Box>
    )
  }

  const renderAveragesIcon = (iconType: string) => {
    switch (iconType) {
      case 'up':
        return <ArrowUpwardIcon className={classes.lightRedArrow} />
      case 'down':
        return <ArrowDownwardIcon className={classes.lightGreenArrow} />
      default:
        return <RemoveIcon className={classes.lightNavyArrow} />
    }
  }

  const renderAverages = (
    averageChangePercent: number,
    averageValue: number,
    label: string,
    boxContainerClass: string
  ) => {
    let averageIconParameter = 'dash'
    let chipClassName = classes.lightNavyChip

    if (averageChangePercent > 0) {
      averageIconParameter = 'up'
      chipClassName = classes.lightRedChip
    } else if (averageChangePercent < 0) {
      averageIconParameter = 'down'
      chipClassName = classes.lightGreenChip
    }

    const renderChip = () => (
      <Chip
        icon={renderAveragesIcon(averageIconParameter)}
        label={`${Math.round(averageChangePercent)}%`}
        className={chipClassName}
      />
    )

    return (
      <Box className={boxContainerClass}>
        <Box className={classes.chipContainer}>{renderChip()}</Box>
        <Box className={classes.labelContainer}>
          <Typography>
            <span className={classes.dailyAverageTextSpan}>{label}</span>
            <span className={classes.dailyAverageValueSpan}>
              {` ${Math.round(averageValue)}`}
            </span>
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Paper className={classes.paperContainer}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.gridContainer}
      >
        <Grid item xs={12}>
          {renderDateLabel()}
        </Grid>

        <Grid item xs={12}>
          {renderTotalAlarmAndChip()}
        </Grid>

        <Grid item xs={12}>
          {renderAverages(
            lastMonthDailyAverageChangePercent,
            lastMonthDailyAverage,
            'Last Month Daily Average:',
            classes.boxContainer
          )}
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {renderAverages(
            lastQuarterDailyAverageChangePercent,
            lastQuarterDailyAverage,
            'Last Quarter Daily Average:',
            classes.boxContainerLastQuarter
          )}
        </Grid>
      </Grid>

      <Box></Box>
    </Paper>
  )
}

export default OverviewTotalAlarms
