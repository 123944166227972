import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { REFRESH_INTERVAL_DOWNSYSTEMS } from '../../../Hardware/Sonar/Constants/SonarConstants'
import downSystemsHelper from './DownSystemsHelper'
import { ISonarDownSystem, TSortOrder } from '../../../Common/Logic/Types'
import { atomSonarFilter } from '../../../Common/atoms'
import downSystemsTableHelper from './DownSystemsTable/DownSystemsTableHelper'

export const useDownSystems = () => {
  const [lastRefreshed, setLastRefreshed] = useState<string>(
    downSystemsHelper.formatTime(new Date()) // Initial state set with formatted time
  )
  const [order, setOrder] = useState<TSortOrder>('desc')
  const [orderBy, setOrderBy] = useState<string>('lastSeenTimestamp')
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [tableCount, setTableCount] = useState<number>(0)
  const [downSystemsData, setDownSystemsData] = useState<any[]>([])
  const [snoozeSavedFlag, setSnoozeSavedFlag] = useState<boolean>(false)
  const [downSystemsDownloadData, setDownSystemsDownloadData] = useState<
    ISonarDownSystem[] | []
  >([])

  const sonarFilter = useRecoilValue(atomSonarFilter)
  const [csvFilename, setCsvFilename] = useState<string>(
    downSystemsHelper.defineSonarDataFileName(sonarFilter, 'down_system')
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [initiateDownload, setInitiateDownload] = useState(false)
  const csvRef = useRef(null)

  const stringifiedSonarFilter = useMemo(() => JSON.stringify(sonarFilter), [
    sonarFilter,
  ])

  const retrieveDownSystemsData = useCallback(
    async abortController => {
      // Skip API call if required filters are not selected

      const parsedSonarFilter = JSON.parse(stringifiedSonarFilter)
      console.log(parsedSonarFilter)
      if (
        !parsedSonarFilter.technology?.length ||
        !parsedSonarFilter.snoozeStatus?.length ||
        (!parsedSonarFilter.lastSeenDateRange?.length &&
          !parsedSonarFilter.timeDown?.length)
      )
        return
      console.log('made it')
      const { count, results } = await downSystemsHelper.fetchDownSystemsData(
        rowsPerPage,
        page * rowsPerPage,
        order,
        orderBy,
        parsedSonarFilter,
        setIsLoading,
        abortController
      )

      const digestedDownSystemData = downSystemsHelper.digestDownSystemsData(
        results
      )

      setTableCount(count)
      setDownSystemsData(digestedDownSystemData)
      setLastRefreshed(downSystemsHelper.formatTime(new Date()))
    },
    [order, orderBy, page, rowsPerPage, stringifiedSonarFilter]
  )

  const retrieveDownSystemsDataDownload = useCallback(
    async abortController => {
      const fileName = downSystemsHelper.defineSonarDataFileName(
        sonarFilter,
        'down_system'
      )
      setCsvFilename(fileName)

      const { results } = await downSystemsHelper.fetchDownSystemsData(
        tableCount,
        0,
        order,
        orderBy,
        sonarFilter,
        setIsLoading
      )

      // Set the isSnoozed property based on the snoozeId and remove the snoozeId from the download data
      const updatedResults = results.map((result: ISonarDownSystem) => {
        const { snoozeId, ...rest } = result
        return {
          ...rest,
          snoozed: String(snoozeId !== null),
          daysDown: downSystemsHelper.getDaysDown(result.lastSeenTimestamp),
          snoozeReason: downSystemsTableHelper.getDownSystemSnoozeReasonForTooltip(
            result.snoozeReason
          ),
          technologyType: downSystemsTableHelper.convertTechnologyType(
            result.technologyType
          ),
        }
      })
      setDownSystemsDownloadData(updatedResults)
      setInitiateDownload(true)
    },
    [order, orderBy, sonarFilter, tableCount]
  )

  const handleTableColumnSort = useCallback(
    (_event: React.MouseEvent<HTMLSpanElement, MouseEvent>, column: string) => {
      // Handle the sorting logic
      const isAsc = orderBy === column && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(column)
    },
    [order, orderBy]
  )

  const onChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage)
    },
    []
  )

  const onChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value))
    },
    []
  )

  useEffect(() => {
    const abortController = new AbortController()
    const interval = setInterval(() => {
      retrieveDownSystemsData(abortController)
    }, REFRESH_INTERVAL_DOWNSYSTEMS)

    return () => {
      abortController.abort()
      clearInterval(interval)
    }
  }, [retrieveDownSystemsData])

  useEffect(() => {
    const abortController = new AbortController()
    retrieveDownSystemsData(abortController)

    return () => {
      abortController.abort()
    }
  }, [retrieveDownSystemsData, snoozeSavedFlag])

  // Reset pagination when sonarFilter changes
  useEffect(() => {
    setPage(0)
  }, [sonarFilter, setPage])

  useEffect(() => {
    if (initiateDownload && csvRef?.current) {
      // @ts-ignore
      csvRef.current.link.click()
      setInitiateDownload(false)
    }
  }, [initiateDownload])

  return {
    csvFilename,
    csvRef,
    downSystemsData,
    downSystemsDownloadData,
    handleTableColumnSort,
    lastRefreshed,
    onChangePage,
    onChangeRowsPerPage,
    order,
    orderBy,
    page,
    retrieveDownSystemsDataDownload,
    rowsPerPage,
    tableCount,
    setSnoozeSavedFlag,
    isLoading,
  }
}

const hook = {
  useDownSystems,
}

export default hook
