import { AuthProvider } from '@carboncure/fusion-auth-component'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { MuiThemeProvider } from '@material-ui/core/styles/'
import theme from './theme/muiTheme'
import { RecoilRoot } from 'recoil'
import { AppBar, Typography } from '@material-ui/core'
import './App.css'
import AppRouter from './AppRouter'
import ErrorSnackbar from './Common/Components/ErrorSnackbar/ErrorSnackbar'

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <RecoilRoot>
        <AuthProvider loginLink={process.env.REACT_APP_FUSION_LOGIN_LINK}>
          <MuiThemeProvider theme={theme}>
            {process.env.REACT_APP_ENV !== 'prod' && (
              <AppBar
                style={{ backgroundColor: 'red', alignItems: 'center' }}
                position="sticky"
              >
                <Typography variant="h1">
                  YOU ARE IN A {process.env.REACT_APP_ENV.toUpperCase()}{' '}
                  ENVIRONMENT, VISIT{' '}
                  <a href="https://orca.carboncure.com">orca.carboncure.com</a>{' '}
                  TO SEE THE REAL DEAL
                </Typography>
              </AppBar>
            )}
            <AppRouter />
            <ErrorSnackbar />
          </MuiThemeProvider>
        </AuthProvider>
      </RecoilRoot>
    </MuiPickersUtilsProvider>
  )
}

export default App
