import React from 'react'
import {
  Backdrop,
  Box,
  CircularProgress,
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core'
import theme from '../../../theme/muiTheme'
import hook from './useOverview'
import OverviewTotalAlarms from './OverviewTotalAlarms/OverviewTotalAlarms'
import SystemSummary from './SystemSummary/SystemSummary'
import { OverviewUptimeDowntime } from './OverviewUptimeDowntime/OverviewUptimeDowntime'
import { OverviewTypeOfAlarms } from './OverviewTypeOfAlarms/OverviewTypeOfAlarms'

const useStyles = makeStyles(() => ({
  backDropZIndex: {
    zIndex: 50,
  },
  containerOverview: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  headerOverview: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  lastRefreshedTextOverview: {
    ...theme.typography.body2,
    fontStyle: 'italic',
    opacity: 0.6,
  },
  subTitleOverview: {
    ...theme.typography.subtitle2,
  },
  tableWrapperOverview: {
    height: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    width: '100%',
  },
  gridItem: {
    padding: '8px',
  },
  boxWrapper: {
    width: '100%',
    height: '100%',
  },
  tabIcon: {
    marginRight: '8px',
    width: '24px',
    height: '24px',
  },
  // Temporary only to show the graph items placeholder
  boxItem: {
    border: '1px solid #ccc',
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#f0f0f0',
    minHeight: '200px',
    flex: '1 1 calc(50% - 16px)',
    boxSizing: 'border-box',
    margin: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    wordWrap: 'break-word',
    flexDirection: 'column',
  },
}))

interface IOverview {
  isFilterOpen: boolean
}

const Overview = (props: IOverview) => {
  const { isFilterOpen } = props
  const classes = useStyles()
  const {
    alarmsBreakdownData,
    iconSrc,
    isLoading,
    lastRefreshed,
    systemSummaryData,
    systemUptimeVsDowntimeData,
    totalAlarmsData,
  } = hook.useOverview()

  return (
    <>
      <Backdrop open={isLoading} className={classes.backDropZIndex}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container direction="column">
        <Grid item className={classes.containerOverview}>
          <Typography
            variant="h3"
            align="left"
            className={classes.headerOverview}
          >
            <img
              src={iconSrc}
              className={classes.tabIcon}
              alt="Overview Icon"
            />
            <span>Overview</span>
          </Typography>
          <Typography
            variant="body2"
            className={classes.lastRefreshedTextOverview}
          >
            Last Refreshed: {lastRefreshed}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className={classes.subTitleOverview}>
            At a glance summary of current active and down systems, rolling
            monthly average of daily alarms, type of active alarms, and system
            uptime status over the last 30 days, with comparison to previous
            30-day and yearly averages. Additionally, view a breakdown of all
            the current active alarm types.
          </Typography>
        </Grid>

        <Grid item>
          <Box className={classes.tableWrapperOverview}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Grid item sm={12} md={6} className={classes.gridItem}>
                <Box className={classes.boxWrapper}>
                  <OverviewTotalAlarms
                    totalAlarmsCount={totalAlarmsData?.totalAlarmsCount}
                    newAlarmsLast24HoursCount={
                      totalAlarmsData?.newAlarmsLast24HoursCount
                    }
                    lastMonthDailyAverage={
                      totalAlarmsData?.lastMonthDailyAverage
                    }
                    lastMonthDailyAverageChangePercent={
                      totalAlarmsData?.lastMonthDailyAverageChangePercent
                    }
                    lastQuarterDailyAverage={
                      totalAlarmsData?.lastQuarterDailyAverage
                    }
                    lastQuarterDailyAverageChangePercent={
                      totalAlarmsData?.lastQuarterDailyAverageChangePercent
                    }
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6} className={classes.gridItem}>
                <Box className={classes.boxWrapper}>
                  <SystemSummary
                    systemSummaryData={systemSummaryData}
                    isFilterOpen={isFilterOpen}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box className={classes.boxWrapper}>
                  <OverviewTypeOfAlarms
                    chartData={alarmsBreakdownData}
                    isFilterOpen={isFilterOpen}
                  />
                </Box>
              </Grid>

              <Grid item sm={12} md={6}>
                <Box className={classes.boxWrapper}>
                  <OverviewUptimeDowntime
                    chartData={systemUptimeVsDowntimeData}
                    isFilterOpen={isFilterOpen}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Overview
