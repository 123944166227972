import { Divider, Grid, Typography } from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import CommissionReportMixDesignDetails from '../CommissionReportMixDesignDetails'
import { ICommissionReportSettings, IMixSelection } from '../../Logic/Types'
import CommissionReportTestSummary from './CommissionReportTestSummary'
import { ICommissionReportCustomer } from '../../Views/CommissionReportGeneratorView'

export interface ICommissionReportTestingOverview {
  mixSelections: IMixSelection[]
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  reportSettings: ICommissionReportSettings
  isLoadingMixData: boolean
  selectedCustomer: ICommissionReportCustomer
  isValidReportId: boolean
  setHasUserInput: Dispatch<SetStateAction<boolean>>
}

const CommissionReportTestingOverview = (
  props: ICommissionReportTestingOverview
) => {
  const {
    reportSettings,
    mixSelections,
    setReportSettings,
    isLoadingMixData,
    selectedCustomer,
    isValidReportId,
    setHasUserInput,
  } = props
  const isMultiMix = mixSelections.length > 1

  return (
    <Grid container item xs={12} direction="column">
      <Grid item xs={12} style={{ marginBottom: 24 }}>
        <Typography variant="h3">Testing Overview</Typography>
      </Grid>
      <Grid container item direction={isMultiMix ? 'column-reverse' : 'row'}>
        <Grid
          item
          xs={isMultiMix ? 12 : 5}
          md={isMultiMix ? 12 : 4}
          xl={isMultiMix ? 12 : 3}
          style={{
            display: isMultiMix ? 'flex' : undefined,
            justifyContent: isMultiMix ? 'space-around' : undefined,
            gap: isMultiMix ? '24px' : undefined,
            paddingBottom: '8px',
          }}
        >
          {mixSelections?.map(mixSelection => (
            <Grid key={mixSelection.mixDesignId}>
              <CommissionReportMixDesignDetails
                mixSelection={mixSelection}
                reportSettings={reportSettings}
                setReportSettings={setReportSettings}
                isMultiMix={isMultiMix}
                setHasUserInput={setHasUserInput}
              />
            </Grid>
          ))}
        </Grid>
        {isMultiMix && (
          <Grid>
            <Divider style={{ margin: '16px 0px' }} />
            <Typography variant="h4" style={{ margin: '16px 0px' }}>
              Mix Design Details
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={isMultiMix ? 12 : 7}
          md={isMultiMix ? 12 : 8}
          xl={isMultiMix ? 12 : 9}
          style={{ paddingLeft: isMultiMix ? '0px' : '16px' }}
        >
          <CommissionReportTestSummary
            mixSelections={mixSelections}
            reportSettings={reportSettings}
            setReportSettings={setReportSettings}
            isLoadingMixData={isLoadingMixData}
            selectedCustomer={selectedCustomer}
            isValidReportId={isValidReportId}
            setHasUserInput={setHasUserInput}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CommissionReportTestingOverview
