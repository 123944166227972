import React from 'react'
import { Snackbar } from '@material-ui/core'
import { hook } from './UseErrorSnackbar'

const ErrorSnackbar = () => {
  const { error, handleClose } = hook.useErrorSnackbar()

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!error}
      autoHideDuration={5000}
      onClose={handleClose}
      message={error}
    />
  )
}

export default ErrorSnackbar
