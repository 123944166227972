import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, Paper } from '@material-ui/core'
import {
  IDigestedMaterial,
  IMaterial,
  MaterialManagerComponent,
  fetchMaterialConstraints,
} from '../../Logic/Types'
import SimpleTablePresentational from '../../../Common/Components/SimpleTable/SimpleTablePresentational'
import {
  ISimpleTableSettings,
  ITargetEvent,
  SimpleTableSelection,
  SimpleTableTypes,
} from '../../../Common/Logic/Types'
import {
  materialsToMaterialTableCards,
  materialsToMaterialTableRows,
} from '../../Logic/TSSLogic'
import { useRecoilValue } from 'recoil'
import xor from 'lodash.xor'
import { atomJWT, atomMaterialMetadata } from '../../../Common/atoms'
import {
  headCellsMaterialTable,
  spreadsheetHeaders,
} from '../../Constants/MaterialManagerConstants'
import {
  getDate,
  isAbortedFetchError,
} from '../../../Common/Helpers/GeneralHelpers'
import { getMaterialByBatch } from '../../Data/TSSDataHelpers'
import { IFilterSettings } from '../../Views/MaterialManagerView'
import MergeMaterialModalLogical from '../MergeMaterialModal/MergeMaterialModalLogical'
import { CSVLink } from 'react-csv'
import { digestMaterialsForDownload } from '../../Helpers/MaterialManagerHelpers'

export interface IMaterialTableLogicalProps {
  setCurrentView: (view: MaterialManagerComponent) => void
  setIsLoading: (arg0: boolean) => void
  filterSettings: IFilterSettings
  setSelectedMaterial: (arg0: IMaterial) => void
  setIsFilterPanelOpen: (arg0: boolean) => void
  isFilterPanelOpen?: boolean
  setIsUnclassifiedMaterial: (arg0: boolean) => void
}

const defaultTableSettings: ISimpleTableSettings = {
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: 'divisionName',
}

function MaterialTableLogical(props: IMaterialTableLogicalProps) {
  const {
    setIsLoading,
    setCurrentView,
    filterSettings,
    setSelectedMaterial,
    setIsFilterPanelOpen,
    isFilterPanelOpen,
    setIsUnclassifiedMaterial,
  } = props
  const [materialTableSettings, setMaterialTableSettings] = useState(
    defaultTableSettings
  )
  const [materials, setMaterials] = useState<Array<IMaterial>>(() => [])
  const [materialsForDownload, setMaterialsForDownload] = useState<
    Array<IMaterial>
  >([])
  const [materialCount, setMaterialCount] = useState<number>(0)
  const [collapsedMaterials, setCollapsedMaterials] = useState<
    Array<IMaterial>
  >(() => [])
  const JWT = useRecoilValue(atomJWT)
  const metadataMap = useRecoilValue(atomMaterialMetadata)
  const [
    currentMaterial,
    setCurrentMaterial,
  ] = useState<IDigestedMaterial | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const currentTimestamp = getDate(Date.now())

  /**Fetch Data from Kelowna*/
  // Create constraints based on materialTableSettings and filterSettings
  const createConstraints = useCallback(
    (Limit: number, Offset: number, orderBy: string, order: string) => {
      const SortOrder = order === 'asc' ? 'Ascending' : 'Descending'
      let constraints: fetchMaterialConstraints = {
        Limit,
        Offset,
        SortColumn: orderBy,
        SortOrder,
      }

      if (filterSettings.customer.length)
        constraints['divisionId'] = filterSettings.customer[0] as
          | number
          | undefined
      if (filterSettings.materialType.length)
        constraints['primaryMaterialType'] = filterSettings.materialType[0] as
          | string
          | undefined
      if (filterSettings.materialSubtype.length)
        constraints['materialType'] = filterSettings.materialSubtype[0] as
          | string
          | undefined
      if (filterSettings.supplierCompany.length)
        constraints['supplierCompany'] = filterSettings.supplierCompany[0] as
          | string
          | undefined
      if (filterSettings.supplierPlant.length)
        constraints['supplierPlant'] = filterSettings.supplierPlant[0] as
          | string
          | undefined
      if (filterSettings.showOnlyIngested) constraints['isOrcaUpdated'] = false

      return constraints
    },
    [filterSettings]
  )

  // Fetch current page for material table
  useEffect(() => {
    const abortController = new AbortController()
    const { order, orderBy, rowsPerPage, page } = materialTableSettings

    const constraints = createConstraints(
      rowsPerPage,
      page * rowsPerPage,
      orderBy,
      order
    )
    const arrayParams = {}

    const fetchDataForTable = async (constraints: fetchMaterialConstraints) => {
      try {
        setIsLoading(true)
        const response = await getMaterialByBatch(
          constraints,
          arrayParams,
          abortController
        )
        if (response.ok) {
          const { count, results } = await response.json()
          setMaterials(results)
          setMaterialCount(count)
          setIsLoading(false)
        }
      } catch (error) {
        // handle error
        if (!isAbortedFetchError(error as Error)) {
          console.error(error)
        }
      }
    }

    fetchDataForTable(constraints)

    return () => {
      abortController.abort()
    }
  }, [materialTableSettings, filterSettings, createConstraints, setIsLoading])

  // Fetch all pages for download
  useEffect(() => {
    const abortController = new AbortController()
    const { order, orderBy } = materialTableSettings

    const constraints = createConstraints(materialCount, 0, orderBy, order)
    const arrayParams = {} // Can be extracted or refactored similarly if there's any redundancy in creating this

    const fetchDataForDownload = async (
      constraints: fetchMaterialConstraints
    ) => {
      try {
        const response = await getMaterialByBatch(
          constraints,
          arrayParams,
          abortController
        )
        if (response.ok) {
          const { results } = await response.json()
          const digestedMaterials = digestMaterialsForDownload(results)
          setMaterialsForDownload(digestedMaterials)
        }
      } catch (error) {
        // handle error
        console.error(error)
      }
    }

    fetchDataForDownload(constraints)
  }, [materialCount, materialTableSettings, filterSettings, createConstraints])

  const handleChangeRowsPerPage = (event: ITargetEvent) => {
    setMaterialTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      }
    })
  }

  const handleChangePage = (event: ITargetEvent, newPage: number) => {
    setMaterialTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        page: newPage,
      }
    })
  }

  const handleRequestSort = (event: ITargetEvent, property: string) => {
    const { orderBy, order } = materialTableSettings
    const isAsc = orderBy === property && order === 'asc'
    setMaterialTableSettings((prev: ISimpleTableSettings) => {
      return {
        ...prev,
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      }
    })
  }

  const handleEditMaterial = (mappingId: number) => {
    if (materials) {
      const material = materials.find(
        obj => obj.materialMappingId === mappingId
      )
      setIsUnclassifiedMaterial(
        material?.primaryMaterialType === null &&
          material?.materialType === null
      )
      setSelectedMaterial(material as IMaterial)
    }
  }

  const handleModal = (id: number) => {
    setModalOpen(true)
    const currentMaterial = materials.find(mat => mat.materialMappingId === id)
    setCurrentMaterial(currentMaterial)
  }

  const { orderBy, order, page, rowsPerPage } = materialTableSettings

  let simpleRows, materialCards, expandedRows
  if (materials) {
    simpleRows = materialsToMaterialTableRows(materials)
    expandedRows = materials.filter(material =>
      collapsedMaterials.includes(material.materialMappingId)
    )
    materialCards = materialsToMaterialTableCards(expandedRows, metadataMap)
  }

  return (
    <>
      <Paper variant="outlined">
        <SimpleTablePresentational
          headCells={headCellsMaterialTable}
          tableType={SimpleTableTypes.MaterialManager}
          selectionType={SimpleTableSelection.None}
          rows={simpleRows}
          isExpandable={true}
          expanded={collapsedMaterials}
          onCollapseToggle={(materialMappingId: number | string) => {
            setCollapsedMaterials(xor(collapsedMaterials, [materialMappingId]))
          }}
          materialCards={materialCards}
          materials={materials}
          setCurrentView={setCurrentView}
          handleEditMaterial={handleEditMaterial}
          rowCount={materialCount}
          rowsPerPage={rowsPerPage}
          page={page}
          order={order}
          orderBy={orderBy}
          onSort={handleRequestSort}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          roles={JWT.roles}
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          isFilterPanelOpen={isFilterPanelOpen}
          handleModal={handleModal}
        />
      </Paper>
      <Grid
        container
        item
        xs={12}
        justify="flex-end"
        style={{ float: 'right', marginTop: '1em' }}
      >
        <CSVLink
          data={materialsForDownload}
          headers={spreadsheetHeaders}
          filename={`material_manager_${currentTimestamp}.csv`}
          id="downloadMaterialData"
        >
          <Button color="primary" variant="outlined" id="downloadMaterialData">
            Download Data
          </Button>
        </CSVLink>
      </Grid>
      {modalOpen && (
        <MergeMaterialModalLogical
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          currentMaterial={currentMaterial}
          setCurrentView={setCurrentView}
          handleEditMaterial={handleEditMaterial}
        />
      )}
    </>
  )
}

export default MaterialTableLogical
