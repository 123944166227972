import { useRecoilValue } from 'recoil'
import { Card, Grid, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import theme from '../../../theme/muiTheme'
import ExpandablePrompt from '../../../Common/Components/ExpandablePrompt/ExpandablePrompt'
import { baseColors } from '../../../theme/colors'
import { getIntervalString } from '../../Helpers/BaleenHelpers'
import {
  ICommissionReportSettings,
  IInsufficientMaturityAgeSamples,
  IInsufficientVariationSamples,
  insufficientSamplesBannerType,
} from '../../Logic/Types'
import { getConnectorString } from '../../../Common/Helpers/GeneralHelpers'
import { atomJWT } from '../../../Common/atoms'
import { tssCanRead } from '../../Logic/TSSLogic'

export interface IInsufficientSamplesBannerProps {
  variationsWithInsufficientSamples?: IInsufficientVariationSamples[]
  variationsWithInsufficientIntervalSamples?: IInsufficientMaturityAgeSamples
  reportSettings: ICommissionReportSettings
  bannerType: insufficientSamplesBannerType
}
const useStyles = makeStyles({
  ...theme.customClasses.expandablePrompt,
  container: {
    ...theme.customClasses.expandablePrompt.container,
    margin: '16px 0px',
  },
  headingContainer: {
    ...theme.customClasses.expandablePrompt.headingContainer,
    backgroundColor: baseColors.error.background,
  },
  expandButton: {
    ...theme.customClasses.expandablePrompt.expandButton,
    color: baseColors.error.dark,
    borderColor: baseColors.error.dark,
  },
  variationCard: {
    padding: '8px 16px',
    boxSizing: 'border-box',
    overflowWrap: 'break-word',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  defaultIntervalInsufficientBannerTypography: {
    marginTop: 8,
  },
  intervalInsufficientBannerTypography: {
    marginTop: 12,
    marginBottom: 8,
  },
})

const InsufficientSamplesBanner = (props: IInsufficientSamplesBannerProps) => {
  const classes = useStyles()
  const {
    bannerType,
    reportSettings,
    variationsWithInsufficientSamples,
    variationsWithInsufficientIntervalSamples = {},
  } = props
  const JWT = useRecoilValue(atomJWT)
  const hasTssReadRole = tssCanRead(JWT.roles)

  const getInsufficientSamplesJSX = () => {
    if (!variationsWithInsufficientSamples) return
    return (
      <Grid container spacing={1} style={{ marginBottom: 8, marginTop: 8 }}>
        {variationsWithInsufficientSamples.map(
          (data: IInsufficientVariationSamples) => (
            <Grid key={data.id} item xs={3} spacing={1}>
              <Card className={classes.variationCard}>
                <Typography variant="body2">{data.label}</Typography>
              </Card>
            </Grid>
          )
        )}
      </Grid>
    )
  }

  const getContentText = (interval: any) => {
    if (!variationsWithInsufficientIntervalSamples) return
    const errorVariationIds =
      variationsWithInsufficientIntervalSamples[interval]
    return errorVariationIds.join(', ')
  }

  const getContentJSX = () => {
    const numOfIntervalsWithErrors = Object.keys(
      variationsWithInsufficientIntervalSamples
    ).length

    return (
      <Typography
        variant="body2"
        className={classes.intervalInsufficientBannerTypography}
      >
        There are not enough samples for{' '}
        {Object.keys(variationsWithInsufficientIntervalSamples).map(
          (interval, index) => (
            <React.Fragment key={interval}>
              <strong>{`${getIntervalString(interval)} (${getContentText(
                interval
              )})`}</strong>
              {getConnectorString(numOfIntervalsWithErrors, index)}
            </React.Fragment>
          )
        )}
        . Add more samples to complete the report.
      </Typography>
    )
  }

  const handleBtnClick = () => {
    const atomCurrentCustomer = {
      division: { divisionId: reportSettings.producerId },
      plant: null,
    }
    //store in sessionStorage since recoil cannot carry over new tab
    const jsonCurrentCustomer = JSON.stringify(atomCurrentCustomer)
    sessionStorage.setItem('currentCustomer', jsonCurrentCustomer)

    //open add mix page on new tab
    const targetUrl = `${window.location.origin}/Concrete/AddData/AddBatchData`
    const newTab = window.open(targetUrl, '_blank')
    newTab?.focus()
  }

  const defaultBannerContentTypography = (
    <Typography
      variant="body2"
      className={classes.defaultIntervalInsufficientBannerTypography}
    >
      There are currently not enough samples for the mix codes selected. Three
      or more samples are necessary to complete the commissioning report.
    </Typography>
  )

  return bannerType !== 'default' ? (
    <ExpandablePrompt
      promptType="commissionReportInsufficientSamples"
      headerText="Insufficient Samples"
      contentText={defaultBannerContentTypography}
      expandButtonText="Add Batch Data"
      onClickHandler={handleBtnClick}
      parentClasses={classes}
      bodyJSX={getInsufficientSamplesJSX()}
      showExpandButton={!hasTssReadRole}
    />
  ) : (
    <ExpandablePrompt
      promptType="commissionReportInsufficientSamples"
      headerText="Insufficient Samples"
      contentText={getContentJSX()}
      expandButtonText="Add Batch Data"
      onClickHandler={handleBtnClick}
      parentClasses={classes}
      showExpandButton={!hasTssReadRole}
    />
  )
}

export default InsufficientSamplesBanner
