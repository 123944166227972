import { roundUpToDecimal } from '../../../../Common/Helpers/GeneralHelpers'
import { ISonarOverviewSystemSummary } from '../../../../Common/Logic/Types'
import { baseColors } from '../../../../theme/colors'
import { IFormattedSystemSummaryData } from '../../../Logic/Types'

const formatSystemSummaryData = (
  systemSummaryData: ISonarOverviewSystemSummary | null
): IFormattedSystemSummaryData[] => {
  if (!systemSummaryData) return []
  return [
    {
      name: 'Snoozed',
      legendName: 'Snoozed',
      y: roundUpToDecimal(systemSummaryData.snoozedPercent, 0),
      count: systemSummaryData.snoozedCount,
      color: baseColors.warning.main,
      tooltipPercent: roundUpToDecimal(systemSummaryData.snoozedPercent, 2),
    },
    {
      name: 'Down',
      legendName: 'Down',
      y: roundUpToDecimal(systemSummaryData.downPercent, 0),
      count: systemSummaryData.downCount,
      color: baseColors.error.main,
      tooltipPercent: roundUpToDecimal(systemSummaryData.downPercent, 2),
    },
    {
      name: 'Operational',
      legendName: 'Up',
      y: roundUpToDecimal(systemSummaryData.operationalPercent, 0),
      count: systemSummaryData.operationalCount,
      color: baseColors.success.main,
      tooltipPercent: roundUpToDecimal(systemSummaryData.operationalPercent, 2),
    },
  ]
}

const getTooltip = (
  name: string,
  count: number,
  percent: number,
  dateString: string
) => {
  const systemsLabel = count === 1 ? 'System' : 'Systems'
  return `<div style="width: 217px">
      <div style="display: flex;flex-direction:row">
        <div>
          <span style="font-weight: 600">
            ${name}
          </span>
        </div>
        <div style="margin-left: auto">
          <span style="font-weight: 600">
            ${percent}%
          </span>
        </div>
      </div>
      <div style="display: flex;flex-direction:row">
        <div>Today | ${dateString}</div>
        <div style="margin-left: auto">
          ${count} ${systemsLabel}
        </div>
      </div>
    </div>`
}

export const getDateString = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const getSystemSummarySeries = (
  systemSummaryData: IFormattedSystemSummaryData[]
) => {
  return systemSummaryData.map(item => ({
    name: item.legendName,
    data: [item],
    color: item.color,
  }))
}

const getTooltipPosition = (chart: any, point: any, labelWidth: number) => {
  let x = point.plotX + chart.plotLeft

  // This is to keep the tooltip from extending past the right edge
  if (x + labelWidth > chart.chartWidth) {
    x = chart.chartWidth - labelWidth - 10
  }

  return {
    x: x,
    y: point.plotY + chart.plotTop - 42,
  }
}

export const systemSummaryHelper = {
  formatSystemSummaryData,
  getDateString,
  getSystemSummarySeries,
  getTooltip,
  getTooltipPosition,
}
