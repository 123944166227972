import HighchartsReact from 'highcharts-react-official'
import { useEffect, useLayoutEffect, useRef } from 'react'

/** https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect */
export const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect

/** https://usehooks-ts.com/react-hook/use-interval */
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback)
  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])
  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }
    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

interface IUseReflowGraphDueToFilterPanel {
  isFilterOpen: boolean
}
export const useReflowGraphDueToFilterPanel = (
  props: IUseReflowGraphDueToFilterPanel
) => {
  const { isFilterOpen } = props
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  // reflow chart to fit width when panel opens/closes
  useEffect(() => {
    let timer = setTimeout(() => {
      if (chartComponentRef?.current?.chart)
        chartComponentRef.current?.chart.reflow()
    }, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [isFilterOpen])

  return { chartComponentRef }
}
