import { fetchDataHelper } from '../../../Common/Helpers/DataHelpers'
import {
  IKelownaSonarOverviewAlarmsBreakdown,
  IKelownaSonarOverviewSystemSummary,
  IKelownaSonarOverviewTotalAlarms,
  IKelownaSonarSystemUptimeVsDowntime,
  ISonarFilter,
  ISonarOverviewSystemSummary,
  ISonarOverviewTotalAlarms,
  ISonarOverviewAlarmsBreakdown,
  ISonarSystemUptimeVsDowntime,
} from '../../../Common/Logic/Types'
import {
  OVERVIEW_ENDPOINT_ITEMS,
  overviewApplicableFilters,
  typeOfAlarmColors,
} from '../Constants/SonarConstants'
import cloneDeep from 'lodash.clonedeep'

/**
 * Defines the filter for the Overview tab.
 * @param {ISonarFilter} sonarFilter - Filter object containing filter items to be used.
 * @returns {Object} - Filter object to passed in the API call.
 */
const defineOverviewFilter = (sonarFilter: ISonarFilter) => {
  // As each endpoint has different filter parameter, we need to map the Sonar atom filter keys to the filter items.
  const sonafFilterParams = overviewApplicableFilters.reduce(
    (acc, { atomKey, filterKey }) => {
      const value = sonarFilter[atomKey]
      if (
        value !== undefined &&
        value !== null &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        acc[filterKey] = value
      }
      return acc
    },
    {} as Record<string, any>
  )
  return sonafFilterParams
}

/**
 * Fetch Overview Data
 */
const fetchOverviewData = async (
  sonarFilter: ISonarFilter,
  endpoint: string,
  abortController: AbortController,
  endpointKey: string
) => {
  const sonarFilterParams = defineOverviewFilter(sonarFilter)

  const params = {
    ...sonarFilterParams,
  }

  const arrayParams = {
    technologyTypes: sonarFilter.technology,
  }

  const response = await fetchDataHelper(
    endpoint,
    params,
    arrayParams,
    abortController
  )

  if (response.status === 200) {
    const responseJson = await response.json()

    let returnData:
      | ISonarOverviewSystemSummary
      | ISonarOverviewTotalAlarms
      | ISonarOverviewAlarmsBreakdown[]
      | ISonarSystemUptimeVsDowntime[]
      | null = null

    switch (endpointKey) {
      case 'TotalAlarms': {
        returnData = digestOverviewData(
          responseJson as IKelownaSonarOverviewTotalAlarms,
          () =>
            // @ts-ignore
            OVERVIEW_ENDPOINT_ITEMS[endpointKey]
              .errorReturn as ISonarOverviewTotalAlarms
        )
        return returnData
      }

      case 'SystemSummary': {
        returnData = digestOverviewData(
          responseJson as IKelownaSonarOverviewSystemSummary,
          () =>
            // @ts-ignore
            OVERVIEW_ENDPOINT_ITEMS[endpointKey]
              .errorReturn as ISonarOverviewSystemSummary
        )
        return returnData
      }

      case 'AlarmsBreakdown': {
        returnData = digestOverviewData(
          responseJson as IKelownaSonarOverviewAlarmsBreakdown[],
          () =>
            // @ts-ignore
            OVERVIEW_ENDPOINT_ITEMS[endpointKey]
              .errorReturn as ISonarOverviewAlarmsBreakdown[]
        )
        return returnData
      }

      case 'SystemUptimeVsDowntime': {
        returnData = digestOverviewData(
          responseJson as IKelownaSonarSystemUptimeVsDowntime[],
          () =>
            // @ts-ignore
            OVERVIEW_ENDPOINT_ITEMS[endpointKey]
              .errorReturn as ISonarSystemUptimeVsDowntime[]
        )
        return returnData
      }

      default:
        console.error(`Unknown endpoint key: ${endpointKey}`)
        break
    }
  } else {
    console.error(`Failed to fetch Overiew data for key: ${endpointKey}`)
    // @ts-ignore - errorReturn value is dyanmic and defined in the constant
    return null
  }
}

type TKelownaInterfaces =
  | IKelownaSonarOverviewSystemSummary
  | IKelownaSonarOverviewTotalAlarms
  | IKelownaSonarOverviewAlarmsBreakdown[]
  | IKelownaSonarSystemUptimeVsDowntime[]

type TSonarInterfaces =
  | ISonarOverviewSystemSummary
  | ISonarOverviewTotalAlarms
  | ISonarOverviewAlarmsBreakdown[]
  | ISonarSystemUptimeVsDowntime[]

const digestOverviewData = <
  T extends TKelownaInterfaces,
  U extends TSonarInterfaces
>(
  passedKelownaInterface: T,
  createOutputType: () => U
): U => {
  const keys = Object.keys(passedKelownaInterface) as (keyof T)[]

  // deep clone to avoid mutating the previous object
  const outputCopy = cloneDeep(createOutputType())

  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = passedKelownaInterface[key]
    return acc
  }, outputCopy)
}

/**
 * This takes Sonar Uptime vs Downtime (UVD) data and transforms it into an object that
 * can easily supply the chart with all the data it needs to create the necessary
 * bar graphs and labels.
 */

const transformUVDData = (data: ISonarSystemUptimeVsDowntime[] | null) => {
  const emptyChartData = {
    categories: [],
    tooltipDates: {},
    uptimeData: [],
    downtimeData: [],
  }
  if (!data) return emptyChartData
  return data.reduce(
    (acc, item) => {
      // Using short dates for the x axis labels on chart
      const shortDate = `${new Date(2024, item.month - 1, 1).toLocaleString(
        'default',
        {
          month: 'short',
        }
      )} ${item.year.toString().slice(2)}`
      // Using long dates for tooltip header on chart when hovering a bar graph
      const longDate = `${new Date(
        2024,
        item.month - 1,
        1
      ).toLocaleString('default', { month: 'long' })} ${item.year}`

      acc.categories.push(shortDate)
      acc.tooltipDates[shortDate] = longDate
      acc.uptimeData.push(item.uptimePercentage)
      acc.downtimeData.push(item.downtimePercentage)

      return acc
    },
    {
      categories: [] as string[],
      tooltipDates: {} as Record<string, string>,
      uptimeData: [] as number[],
      downtimeData: [] as number[],
    }
  )
}

const toTitleCase = (text: string | null): string => {
  if (!text) return ''

  const exceptions = ['CO2', 'E-Stop']

  return text
    .split(' ')
    .map(word => {
      if (exceptions.includes(word)) {
        return word // Keep the word as it is
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first letter
    })
    .join(' ')
}

const processAlarmsBreakdownData = (
  alarmsBreakdownData: ISonarOverviewAlarmsBreakdown[] | null
) => {
  if (
    !alarmsBreakdownData ||
    alarmsBreakdownData.length === 0 ||
    alarmsBreakdownData[0]?.count === 0 // Default value when no data
  )
    return []

  // Sort by percentage in descending order
  const sortedByPercentage = alarmsBreakdownData.toSorted(
    (a, b) => b.percentage - a.percentage
  )

  // Extract the top 5 items
  const topFiveByPercentage = sortedByPercentage.slice(0, 5)

  // Format the data for pie chart
  const processedData = topFiveByPercentage.map((item, index) => ({
    name: toTitleCase(item.alarmName) || `Alarm Code: ${item.alarmCode}`,
    y: item.percentage,
    count: item.count.toLocaleString(),
    color: typeOfAlarmColors[index],
  }))

  // Only add "Other" if there are more than 5 items
  if (sortedByPercentage.length > 5) {
    const remainingItems = sortedByPercentage.slice(5)
    const otherPercentage = remainingItems.reduce(
      (sum, item) => sum + item.percentage,
      0
    )
    const otherCount = remainingItems.reduce((sum, item) => sum + item.count, 0)

    processedData.push({
      name: 'Other',
      y: otherPercentage,
      count: otherCount.toLocaleString(),
      color: typeOfAlarmColors[typeOfAlarmColors.length - 1], // "Other" color
    })
  }

  return processedData
}

const overviewHelper = {
  defineOverviewFilter,
  digestOverviewData,
  fetchOverviewData,
  processAlarmsBreakdownData,
  transformUVDData,
}

export default overviewHelper
