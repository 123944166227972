import React from 'react'
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { ISonarSystemUptimeVsDowntime } from '../../../../Common/Logic/Types'
import overviewHelper from '../OverviewHelper'
import theme from '../../../../theme/muiTheme'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { useReflowGraphDueToFilterPanel } from '../../../../Common/Hooks/hooks'

const useStyles = makeStyles(() => ({
  //@ts-ignore
  ...theme.customClasses.sonarOverviewVisuals,
}))

interface UptimeDowntimeProps {
  chartData: ISonarSystemUptimeVsDowntime[] | null
  isFilterOpen: boolean
}

export const OverviewUptimeDowntime = (props: UptimeDowntimeProps) => {
  const { chartData, isFilterOpen } = props
  const classes: ClassNameMap<string> = useStyles()
  const {
    categories,
    tooltipDates,
    uptimeData,
    downtimeData,
  } = overviewHelper.transformUVDData(chartData)

  const { chartComponentRef } = useReflowGraphDueToFilterPanel({ isFilterOpen })

  const chartOptions: Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Uptime vs. Downtime',
      margin: 32,
      align: 'left',
      useHTML: true,
      style: {
        fontSize: '18px',
        fontFamily: 'Inter',
      },
    },
    subtitle: {
      text:
        '<br/>Monthly snapshot of uptime vs. downtime of all CarbonCure systems.',
      align: 'left',
      useHTML: true,
      style: {
        fontSize: '14px',
        color: '#000000',
        fontFamily: 'Urbanist',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0.05, // Default is 0.1
        groupPadding: 0.05,
      },
    },
    tooltip: {
      shared: true,
      borderWidth: 0,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderRadius: 12,
      positioner: function(labelWidth, _labelHeight, point) {
        // @SONAR_STOP@
        // Skip sonar scanning as this is HighCharts suggested code functionality
        const chart = this.chart
        // @SONAR_START@
        let x = point.plotX + chart.plotLeft

        // This is to keep the tooltip from extending past the right edge
        if (x + labelWidth > chart.chartWidth) {
          x = chart.chartWidth - labelWidth - 10
        }

        return {
          x: x,
          y: point.plotY + chart.plotTop + 70,
        }
      },
      formatter: function() {
        // @SONAR_STOP@
        // Skip sonar scanning as this is HighCharts suggested code functionality
        const points = this.points || []
        // @SONAR_START@
        // @ts-ignore
        let tooltipText = `${tooltipDates[points[0].x]}<br/>`

          // Tooltip shows data in a reverse order.
        ;[...points].reverse().forEach(point => {
          //
          tooltipText += `<b><span style="color:${
            point.color
          }; font-size: 18px">●</span> ${point.y.toFixed(1)}% (${
            point.series.name
          })</b><br/>`
        })

        return tooltipText
      },
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
      title: {
        text: '',
      },
    },
    yAxis: {
      tickInterval: 20,
      max: 100,
      gridZIndex: 4,
      gridLineColor: 'rgba(176, 176, 176, 0.6)',
      title: {
        text: '',
      },
    },
    series: [
      // @ts-ignore
      {
        name: 'Downtime',
        data: downtimeData,
        color: '#95a5a6',
      },
      // @ts-ignore
      {
        name: 'Uptime',
        data: uptimeData,
        color: '#2f8e5c',
      },
    ],
  }

  return (
    <Paper className={classes.paperContainer}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            style={{ margin: '0px', padding: '0px' }}
            ref={chartComponentRef}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
