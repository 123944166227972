export function hardwareCanRead(roles: string[]) {
  return (
    roles.includes('Hardware') ||
    roles.includes('Hardware_W') ||
    roles.includes('OrcaAdmin')
  )
}

export function hardwareCanWrite(roles: string[]) {
  return roles.includes('Hardware_W') || roles.includes('OrcaAdmin')
}

export function systemDataCanRead(roles: string[]) {
  return roles.includes('Hardware') || roles.includes('OrcaAdmin')
}
