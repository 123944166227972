import { useEffect, useState } from 'react'
import { errorHandler } from '../../Logic/ErrorHandler'

const useErrorSnackbar = () => {
  const [error, setError] = useState<string | null>(null)

  const handleClose = () => {
    errorHandler.hideErrorSnackbar()
  }

  useEffect(() => {
    errorHandler.setCallback(setError)

    return () => {
      errorHandler.setCallback(() => {})
    }
  }, [])

  return {
    error,
    handleClose,
  }
}

export const hook = {
  useErrorSnackbar,
}
