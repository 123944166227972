type ErrorHandlerCallback = (error: string | null) => void
export default class ErrorHandler {
  private callback: ErrorHandlerCallback | null = null

  setCallback(callback: ErrorHandlerCallback) {
    this.callback = callback
  }

  showErrorSnackbar(error: string | null) {
    if (this.callback) {
      this.callback(error)
    }
  }

  hideErrorSnackbar() {
    if (this.callback) {
      this.callback(null)
    }
  }
}

export const errorHandler = new ErrorHandler()
