import {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import { SnoozeAlarmReason } from '../../../Logic/Types'
import { ISonarAlarm } from '../../../../Common/Logic/Types'
import { DateRange, SonarDateRange } from '../../../../TSS/Logic/Types'
import { addDays, formatISO } from 'date-fns'
import snoozeAlarmModalHelper from './SnoozeAlarmModalHelper'
import snoozeDownSystemsModalHelper from '../SnoozeDownSystemsModal/snoozeDownSystemsModalHelper'
import { useRecoilValue } from 'recoil'
import { atomJWT } from '../../../../Common/atoms'
import { getUTCDateBounds } from '../../Helpers/SonarHelpers'

export interface IUseSnoozeAlarmModal {
  clickedAlarm: ISonarAlarm | null
  setShowSnoozeModal: Dispatch<SetStateAction<boolean>>
  setClickedAlarm: Dispatch<SetStateAction<ISonarAlarm | null>>
  setSnoozeSavedFlag: Dispatch<SetStateAction<boolean>>
  showSnoozeModal: boolean
  setShowSnoozeSuccessModal: Dispatch<SetStateAction<boolean>>
}
const useSnoozeAlarmModal = (props: IUseSnoozeAlarmModal) => {
  const {
    clickedAlarm,
    setClickedAlarm,
    setShowSnoozeModal,
    setSnoozeSavedFlag,
    showSnoozeModal,
    setShowSnoozeSuccessModal,
  } = props
  const { currentDateUTC } = getUTCDateBounds(new Date())
  const defaultSnoozeEnd = formatISO(addDays(currentDateUTC, 1)).substring(
    0,
    10
  )
  const [reason, setReason] = useState<SnoozeAlarmReason | null>(null)
  const [reasonDetails, setReasonDetails] = useState<string>('')
  const [rangeSelected, setRangeSelected] = useState<SonarDateRange | null>(
    SonarDateRange.OneDay
  )
  const [snoozeEnd, setSnoozeEnd] = useState<DateRange>(defaultSnoozeEnd)
  const [snoozeEditDate, setSnoozeEditDate] = useState<string | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isSavingModal, setIsSavingModal] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const defaultModalValuesLoaded = useRef(false)
  const lastUpdatedDate = useMemo(
    () =>
      snoozeAlarmModalHelper.getLastUpdatedDate(
        clickedAlarm?.snoozeLastUpdated
      ),
    [clickedAlarm]
  )
  const JWT = useRecoilValue(atomJWT)

  const handleReasonChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newReason = event.target.value as SnoozeAlarmReason
      setReason(newReason)
      setReasonDetails('')
    },
    []
  )

  const handleReasonDetailsChange = useCallback((newReasonDetails: string) => {
    setReasonDetails(newReasonDetails)
  }, [])

  const handleSnoozeModalClose = useCallback(() => {
    setShowSnoozeModal(false)
    setReason(null)
    setReasonDetails('')
    setRangeSelected(SonarDateRange.OneDay)
    setSnoozeEnd(defaultSnoozeEnd)
    setSnoozeEditDate(null)
    setClickedAlarm(null)
    defaultModalValuesLoaded.current = false
    setErrorMessage(null)
  }, [setShowSnoozeModal, setClickedAlarm, defaultSnoozeEnd])

  const handleSnoozeSuccessModalClose = useCallback(() => {
    handleSnoozeModalClose()
    setShowSnoozeSuccessModal(false)
  }, [handleSnoozeModalClose, setShowSnoozeSuccessModal])

  const handleSnoozeSuccessModalOpen = useCallback(() => {
    setShowSnoozeModal(false)
    setShowSnoozeSuccessModal(true)
  }, [setShowSnoozeModal, setShowSnoozeSuccessModal])

  const populateErrorState = (err: any) => {
    if (err instanceof Error) {
      setErrorMessage(err.message)
    } else {
      setErrorMessage(String(err))
    }
  }

  const handleConfirmSnooze = useCallback(async () => {
    setIsSaving(true)
    setErrorMessage(null)
    if (!clickedAlarm) return
    if (clickedAlarm.snoozed && clickedAlarm.snoozeId) {
      try {
        await snoozeAlarmModalHelper.editSnooze(
          clickedAlarm.snoozeId,
          reason,
          reasonDetails,
          snoozeEditDate
        )
        handleSnoozeModalClose()
        setSnoozeSavedFlag(prev => !prev)
      } catch (err) {
        console.error(err)
        populateErrorState(err)
      } finally {
        setIsSaving(false)
      }
    } else {
      try {
        await snoozeAlarmModalHelper.saveSnooze(
          clickedAlarm.alarmId,
          reason,
          reasonDetails,
          snoozeEnd,
          clickedAlarm.technology
        )
        handleSnoozeModalClose()
        setSnoozeSavedFlag(prev => !prev)
      } catch (err) {
        console.error(err)
        populateErrorState(err)
      } finally {
        setIsSaving(false)
      }
    }
  }, [
    clickedAlarm,
    handleSnoozeModalClose,
    reason,
    reasonDetails,
    setSnoozeSavedFlag,
    snoozeEnd,
    snoozeEditDate,
  ])

  const handleUnsnooze = useCallback(async () => {
    setIsSavingModal(true)
    if (!clickedAlarm) return
    try {
      await snoozeDownSystemsModalHelper.unSnooze(clickedAlarm.snoozeId)
      handleSnoozeSuccessModalOpen()
      setSnoozeSavedFlag(prev => !prev)
    } catch (err) {
      console.error(err)
    } finally {
      setIsSavingModal(false)
    }
  }, [clickedAlarm, handleSnoozeSuccessModalOpen, setSnoozeSavedFlag])

  const handleDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newDate = event.target.value
      setSnoozeEditDate(newDate)
    },
    []
  )

  // Effects

  /* Use current data to populate input fields when editing snooze */
  useEffect(() => {
    if (!showSnoozeModal || defaultModalValuesLoaded.current) return

    const { snoozeReason, snoozeDetails, snoozedUntilTimestamp } =
      clickedAlarm || {}

    if (snoozeReason && snoozeReason !== reason) {
      setReason(snoozeReason as SnoozeAlarmReason)
    }

    if (snoozeDetails && snoozeDetails !== reasonDetails) {
      setReasonDetails(snoozeDetails)
    }

    if (snoozedUntilTimestamp && snoozedUntilTimestamp !== snoozeEditDate) {
      setSnoozeEditDate(snoozedUntilTimestamp)
    }

    defaultModalValuesLoaded.current = true
  }, [clickedAlarm, reason, reasonDetails, showSnoozeModal, snoozeEditDate])

  return {
    errorMessage,
    handleConfirmSnooze,
    handleDateChange,
    handleReasonChange,
    handleReasonDetailsChange,
    handleSnoozeModalClose,
    handleSnoozeSuccessModalClose,
    handleSnoozeSuccessModalOpen,
    handleUnsnooze,
    isSaving,
    isSavingModal,
    JWT,
    lastUpdatedDate,
    rangeSelected,
    reason,
    reasonDetails,
    setRangeSelected,
    setSnoozeEnd,
    snoozeEditDate,
    snoozeEnd,
  }
}

const hook = {
  useSnoozeAlarmModal,
}

export default hook
