import React, { Dispatch, SetStateAction, useEffect } from 'react'
import {
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Add, DeleteOutlined } from '@material-ui/icons'
import {
  ICommissionReportSettings,
  ICommissionReportVariation,
  ICommissionReportVariationOption,
  IInsufficientVariationSamples,
  IMixGroupOptionWithConditions,
  IMixSelection,
  IReportMixSelectionSettings,
  MixSelectionLabels,
  TestCategoriesKey,
  TestCategoryOption,
  VariationTypes,
} from '../../Logic/Types'
import { IMixSelectionRow } from '../../Views/CommissionReportGeneratorView'
import cloneDeep from 'lodash.clonedeep'
import { getCO2DosageUnitLabel } from '../../Logic/TSSLogic'
import {
  computeAverageAndStdDev,
  getMixGroupIntervalOptions,
  getSelectedVariation,
  handleVariationChangeHelper,
  handleVariationSwapHelper,
  isVariationSampleCountInsufficient,
  populateBarGraphIntervals,
  populateHistogramIntervals,
  reportSettingsMixGroupChangeHelper,
  updateSelectedTestCategories,
} from '../../Helpers/CommissionReportHelpers'
import MixGroupOptionWithBaselineConditions from '../MixGroupOptionWithBaselineConditions'
import theme from '../../../theme/muiTheme'
import breakpoints from '../../../theme/breakpoints'
import { Autocomplete } from '@material-ui/lab'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { baseColors } from '../../../theme/colors'
import CommissionReportMixGroupSelect from './CommissionReportMixGroupSelect'

const useStyles = makeStyles(() => ({
  chipRoot: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    margin: '4px',
    padding: '2px',
    maxWidth: '100%',
    '& .MuiAvatar-root': {
      margin: '4px 0px',
    },
    '& .MuiChip-label': {
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      textOverflow: 'clip',
      fontSize: 12,
      maxWidth: 160,
      [theme.breakpoints.down(Number(breakpoints.sm) - 1)]: {
        maxWidth: '95%',
      },
    },
  },
  multiSelectRoot: {
    '& .MuiOutlinedInput-root': {
      minHeight: 'auto',
      '&:not(.Mui-focused)': {
        /*
        When focusing on this input, it makes space for the cursor by expanding 
        vertically so that there's space for the next chip. But that space remains 
        when the input isn't focused. This css styling shrinks the input back to normal.
        */
        '& .MuiAutocomplete-input': {
          padding: '0 !important',
          height: '0 !important',
          minHeight: '0 !important',
          opacity: 0,
        },
      },
    },
  },
  gridContainer: {
    minWidth: '130px',
    maxWidth: '260px',
    [theme.breakpoints.down(Number(breakpoints.xl) - 1)]: {
      maxWidth: '180px',
    },
  },
  errorIcon: {
    color: baseColors.error.main,
  },
}))

interface IMixSelectionProps {
  readonly onMixSelectionChange: (isSelected: boolean) => void
  readonly reportSettings: ICommissionReportSettings
  readonly mixSelections: IMixSelection[]
  readonly mixSelectionRows: IMixSelectionRow[]
  readonly mixGroupOptions: IMixGroupOptionWithConditions[]
  readonly setMixSelectionRows: Dispatch<SetStateAction<IMixSelectionRow[]>>
  readonly setCallMixEndpointFlag: Dispatch<SetStateAction<boolean>>
  readonly setReportSettings: Dispatch<
    SetStateAction<ICommissionReportSettings>
  >
  readonly setMixSelections: Dispatch<SetStateAction<IMixSelection[]>>
  readonly setIsLoadingMixData: Dispatch<SetStateAction<boolean>>
  readonly variationsWithInsufficientSamplesList: IInsufficientVariationSamples[]
  readonly setHasUserInput: Dispatch<SetStateAction<boolean>>
}

function MixSelection(props: IMixSelectionProps) {
  const {
    onMixSelectionChange,
    mixSelectionRows,
    setMixSelectionRows,
    mixSelections,
    mixGroupOptions,
    reportSettings,
    setCallMixEndpointFlag,
    setReportSettings,
    setMixSelections,
    setIsLoadingMixData,
    variationsWithInsufficientSamplesList,
    setHasUserInput,
  } = props
  const classes = useStyles()

  const handleMixGroupChange = (
    index: number,
    value: IMixGroupOptionWithConditions
  ) => {
    setIsLoadingMixData(true)
    setMixSelectionRows((prevRows: IMixSelectionRow[]) => {
      return prevRows.map((row, i) =>
        i === index
          ? { ...row, mixDesignId: value.id ? (value.id as number) : null }
          : row
      )
    })
    setReportSettings(prevSettings => {
      const newReportSettings = cloneDeep(prevSettings)
      reportSettingsMixGroupChangeHelper(
        prevSettings,
        newReportSettings,
        index,
        value.id as number
      )
      return newReportSettings
    })
    setCallMixEndpointFlag(true)
    setHasUserInput(true)
  }

  const handleDeleteMixClick = (index: number) => {
    const isMixGroupSelected = !!mixSelectionRows[index].mixDesignId
    const mixSelectionRowsCopy = cloneDeep(mixSelectionRows)
    setMixSelectionRows(prevRows => {
      const newArr = []
      for (let i = 0; i < prevRows.length; i++) {
        if (i === index) continue
        newArr.push(prevRows[i])
      }
      return newArr
    })
    if (!isMixGroupSelected) return
    setReportSettings(prevSettings => {
      const newReportSettings = cloneDeep(prevSettings)
      const mixDesignToRemove =
        prevSettings.mixDesignSettings[index].mixDesignId
      newReportSettings.mixDesignSettings = prevSettings.mixDesignSettings.filter(
        (settings: IReportMixSelectionSettings) =>
          settings.mixDesignId !== mixDesignToRemove
      )
      return newReportSettings
    })
    setMixSelections(prevSelections => {
      return prevSelections.filter(
        selection =>
          selection.mixDesignId !== mixSelectionRowsCopy[index].mixDesignId
      )
    })
    setHasUserInput(true)
  }

  const handleAddMixClick = () => {
    setMixSelectionRows(prevRows => {
      const usedIds = new Set()
      prevRows.forEach(row => usedIds.add(row.id))
      let newId = Infinity
      for (let i = 0; i < 3; i++) {
        if (!usedIds.has(i)) newId = i
      }
      return [...prevRows, { id: newId, mixDesignId: null }]
    })
    setHasUserInput(true)
  }

  const getIsMixGroupOptionDisabled = (
    option: IMixGroupOptionWithConditions,
    index: number
  ): boolean => {
    for (let i = 0; i < mixSelections.length; i++) {
      if (i === index) continue
      if (option.id === mixSelections[i].mixDesignId) return true
    }
    return false
  }

  const getIsVariationOptionDisabled = (
    option: ICommissionReportVariationOption,
    index: number,
    variationType: VariationTypes
  ) => {
    const mixSelection = mixSelections[index]
    const digestedVariations = mixSelection.digestedVariations
    if (mixSelections[index].variationOptions.length === 2) return false
    for (const variation of digestedVariations) {
      if (
        variationType === VariationTypes.BASELINE &&
        option.id === variation.variationId &&
        variation.orcaVariationType === VariationTypes.OPTIMIZED
      )
        return true
      if (
        variationType === VariationTypes.OPTIMIZED &&
        option.id === variation.variationId &&
        variation.orcaVariationType === VariationTypes.BASELINE
      )
        return true
    }
    return false
  }

  const getVariationTestCategoryOptions = (
    variationType: VariationTypes,
    mixSelection: IMixSelection
  ) => {
    const match = mixSelection?.digestedVariations.find(
      variation => variation.orcaVariationType === variationType
    )
    if (!match) return []
    return match.testCategoryOptions
  }

  const getBaselineSelectedTestCategories = (mixSelection: IMixSelection) => {
    const match = mixSelection?.digestedVariations.find(
      variation => variation.orcaVariationType === VariationTypes.BASELINE
    )
    if (!match) return []
    const matchedSettings = reportSettings.mixDesignSettings.find(
      settings => settings.mixDesignId === mixSelection.mixDesignId
    )
    if (
      !matchedSettings?.baselineVariation ||
      !matchedSettings?.carbonCureVariation
    )
      return []
    const cO2DosageUnitLabel =
      getCO2DosageUnitLabel(
        matchedSettings.baselineVariation.cO2DosageUnit,
        reportSettings.isMetric
      ) || ''
    const variationId = `${mixSelection.mixDesignId}-${matchedSettings.baselineVariation.cementReductionPercent}-${matchedSettings.baselineVariation.cO2Dosage}${cO2DosageUnitLabel}`
    if (match.variationId === variationId)
      return match.testCategoryOptions.filter(option => {
        return matchedSettings.baselineVariation?.selectedTestCategories.includes(
          option.id as TestCategoriesKey
        )
      })
    else return []
  }

  const getCarbonCureSelectedTestCategories = (mixSelection: IMixSelection) => {
    const match = mixSelection?.digestedVariations.find(
      variation => variation.orcaVariationType === VariationTypes.OPTIMIZED
    )
    if (!match) return []
    const matchedSettings = reportSettings.mixDesignSettings.find(
      settings => settings.mixDesignId === mixSelection.mixDesignId
    )
    if (
      !matchedSettings?.baselineVariation ||
      !matchedSettings?.carbonCureVariation
    )
      return []
    const cO2DosageUnitLabel =
      getCO2DosageUnitLabel(
        matchedSettings.carbonCureVariation.cO2DosageUnit,
        reportSettings.isMetric
      ) || ''
    const variationId = `${mixSelection.mixDesignId}-${matchedSettings.carbonCureVariation.cementReductionPercent}-${matchedSettings.carbonCureVariation.cO2Dosage}${cO2DosageUnitLabel}`
    if (match.variationId === variationId)
      return match.testCategoryOptions.filter(option => {
        return matchedSettings.carbonCureVariation?.selectedTestCategories.includes(
          option.id as TestCategoriesKey
        )
      })
    else return []
  }

  const handleVariationChange = (
    index: number,
    value: ICommissionReportVariationOption,
    variationType: VariationTypes
  ) => {
    const newMixes = cloneDeep(mixSelections)
    const newMix: IMixSelection = newMixes[index]
    const newReportSettings = cloneDeep(reportSettings)
    if (newMix.variationOptions.length === 2) {
      const {
        newBaselineVariation,
        newCarbonCureVariation,
        mixDesignSettings,
      } = handleVariationSwapHelper(newMix, newReportSettings, index)
      if (
        !newBaselineVariation ||
        !newCarbonCureVariation ||
        !mixDesignSettings
      )
        return
      computeAverageAndStdDev(
        newBaselineVariation,
        newReportSettings.mixDesignSettings[index],
        newMix.specifiedMaturityAge
      )
      computeAverageAndStdDev(
        newCarbonCureVariation,
        newReportSettings.mixDesignSettings[index],
        newMix.specifiedMaturityAge
      )
      newMix.intervalOptions = getMixGroupIntervalOptions(
        newMix.digestedVariations
      )
      populateBarGraphIntervals(
        mixDesignSettings.avgStrengthGraph,
        newMix.intervalOptions,
        newMix.specifiedMaturityAge
      )
      populateHistogramIntervals(
        mixDesignSettings.frequencyGraph,
        newMix.intervalOptions,
        newMix.specifiedMaturityAge
      )
    } else {
      const {
        mixDesignSettings,
        newSelectedVariation,
      } = handleVariationChangeHelper(
        newMix,
        newReportSettings,
        index,
        value,
        variationType
      )
      if (!newSelectedVariation || !mixDesignSettings) return
      computeAverageAndStdDev(
        newSelectedVariation,
        newReportSettings.mixDesignSettings[index],
        newMix.specifiedMaturityAge
      )
      newMix.intervalOptions = getMixGroupIntervalOptions(
        newMix.digestedVariations
      )
      populateBarGraphIntervals(
        mixDesignSettings.avgStrengthGraph,
        newMix.intervalOptions,
        newMix.specifiedMaturityAge
      )
      populateHistogramIntervals(
        mixDesignSettings.frequencyGraph,
        newMix.intervalOptions,
        newMix.specifiedMaturityAge
      )
    }
    setMixSelections(newMixes)
    setReportSettings(newReportSettings)
    setHasUserInput(true)
  }

  const renderMixGroupOption = (option: IMixGroupOptionWithConditions) => {
    return <MixGroupOptionWithBaselineConditions option={option} />
  }

  const renderTestCategoryOption = (
    option: TestCategoryOption,
    index: number,
    variationType: VariationTypes
  ) => {
    // if all is an option, render a different look
    const allSelected =
      variationType === VariationTypes.BASELINE
        ? reportSettings.mixDesignSettings[
            index
          ].baselineVariation?.selectedTestCategories.includes(
            'all' as TestCategoriesKey
          )
        : reportSettings.mixDesignSettings[
            index
          ].carbonCureVariation?.selectedTestCategories.includes(
            'all' as TestCategoriesKey
          )
    if (allSelected) {
      return (
        <div>
          <div style={{ marginLeft: option.id !== 'all' ? '8px' : 0 }}>
            {option.label}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div>{option.label}</div>
        </div>
      )
    }
  }

  const filterTestCategory = (
    testCategories: TestCategoryOption[],
    testCategoryToRemove: TestCategoryOption
  ) => {
    const filteredTestCategories = testCategories.filter(
      item => item.id !== testCategoryToRemove.id
    )
    return filteredTestCategories
  }

  const renderTestCategoryTags = (
    value: TestCategoryOption[],
    index: number,
    variationType: VariationTypes
  ) => {
    const isVariationTypeBaseline = variationType === VariationTypes.BASELINE
    const variationSettings = isVariationTypeBaseline
      ? reportSettings.mixDesignSettings[index].baselineVariation
      : reportSettings.mixDesignSettings[index].carbonCureVariation
    if (!variationSettings) return
    const selectedTestCategories = variationSettings.selectedTestCategories
    const variation = mixSelections[index].digestedVariations.find(
      (variation: ICommissionReportVariation) => {
        if (isVariationTypeBaseline)
          return variation.orcaVariationType === VariationTypes.BASELINE
        else return variation.orcaVariationType === VariationTypes.OPTIMIZED
      }
    )
    if (!variation) return
    const testCategoryOptions = variation.testCategoryOptions
    const isAllSelected = selectedTestCategories.includes('all')
    if (isAllSelected) {
      const allOption = value.find(option => option.id === 'all')
      return (
        <>
          {!!allOption && (
            <Chip
              className={classes.chipRoot}
              label={allOption.label}
              size="small"
            />
          )}
        </>
      )
    } else {
      return (
        <>
          {value.map(option => (
            <div key={option.id}>
              <Chip
                className={classes.chipRoot}
                label={option.label}
                size="small"
                onDelete={
                  testCategoryOptions.length > 1
                    ? () => {
                        const filteredCategories = filterTestCategory(
                          value,
                          option
                        )
                        handleTestCategoriesChange(
                          filteredCategories,
                          index,
                          variationType
                        )
                      }
                    : undefined
                }
              />
            </div>
          ))}
        </>
      )
    }
  }

  const handleTestCategoriesChange = (
    values: TestCategoryOption[],
    index: number,
    variationType: VariationTypes
  ) => {
    const variation = mixSelections[index].digestedVariations.find(
      variation => {
        if (variationType === VariationTypes.BASELINE)
          return variation.orcaVariationType === VariationTypes.BASELINE
        else return variation.orcaVariationType === VariationTypes.OPTIMIZED
      }
    )
    if (!variation) return
    const newReportSettings = cloneDeep(reportSettings)
    // Reset selected strength / st dev when test categories are changed
    newReportSettings.mixDesignSettings[
      index
    ].testResults.selectedStrengthHours = []
    newReportSettings.mixDesignSettings[
      index
    ].testResults.selectedStrengthStDevHours = []

    updateSelectedTestCategories(
      variation,
      newReportSettings,
      values,
      index,
      variationType
    )

    setReportSettings(newReportSettings)
    setMixSelections(prevMixSelections => {
      const newMixSelections = cloneDeep(prevMixSelections)
      const mixSelection = newMixSelections[index]
      const variation = mixSelection.digestedVariations.find(
        (variation: ICommissionReportVariation) => {
          if (variationType === VariationTypes.BASELINE)
            return variation.orcaVariationType === VariationTypes.BASELINE
          else return variation.orcaVariationType === VariationTypes.OPTIMIZED
        }
      )
      computeAverageAndStdDev(
        variation,
        newReportSettings.mixDesignSettings[index],
        mixSelection.specifiedMaturityAge
      )
      mixSelection.intervalOptions = getMixGroupIntervalOptions(
        mixSelection.digestedVariations
      )
      populateBarGraphIntervals(
        newReportSettings.mixDesignSettings[index].avgStrengthGraph,
        mixSelection.intervalOptions,
        mixSelection.specifiedMaturityAge
      )
      populateHistogramIntervals(
        newReportSettings.mixDesignSettings[index].frequencyGraph,
        mixSelection.intervalOptions,
        mixSelection.specifiedMaturityAge
      )
      return newMixSelections
    })
    setHasUserInput(true)
  }

  const getIsTestCategoryOptionDisabled = (
    option: TestCategoryOption,
    index: number,
    variationType: VariationTypes
  ) => {
    if (option.id !== 'all') return false
    const variationSettings =
      variationType === VariationTypes.BASELINE
        ? reportSettings.mixDesignSettings[index].baselineVariation
        : reportSettings.mixDesignSettings[index].carbonCureVariation
    if (!variationSettings) return true
    const isAllSelected = variationSettings.selectedTestCategories.some(
      category => category === 'all'
    )
    return isAllSelected
  }

  const getIsAddMixBtnDisabled = () => {
    return (
      mixSelectionRows.some(row => row.mixDesignId === null) ||
      mixSelectionRows.length === 3
    )
  }

  const getIsDeleteIconVisible = (row: IMixSelectionRow) => {
    const mixDesignId = row.mixDesignId
    if (!mixDesignId) return true
    const showIcon = mixSelectionRows.some(
      row => row.mixDesignId && row.mixDesignId !== mixDesignId
    )
    return showIcon
  }

  const getAddMixDisabledTooltip = () => {
    const isRowMissingMixGroup = mixSelectionRows.some(
      row => row.mixDesignId === null
    )
    if (mixSelectionRows.length === 3) return 'Maximum 3 mixes per report'
    else if (isRowMissingMixGroup)
      return 'Please fill out existing row to add another'
    else return ''
  }

  const isFirstMixGroupSelected = !!mixSelectionRows[0]?.mixDesignId
  useEffect(() => {
    // Call the callback prop whenever mixSelections state changes
    const isAnyMixSelected = mixSelectionRows.some(
      selection => !!selection.mixDesignId
    )
    onMixSelectionChange(isAnyMixSelected)
  }, [mixSelectionRows, onMixSelectionChange])
  return (
    <Grid container direction="row" alignItems="center" justify="space-between">
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={3}
          alignItems="flex-start"
          justify="space-between"
          style={{ paddingBottom: '8px' }}
        >
          <Grid item xs={12} md className={classes.gridContainer}>
            <Typography>{MixSelectionLabels.MixGroup}</Typography>
          </Grid>
          <Grid item xs={12} md className={classes.gridContainer}>
            <Typography>
              {isFirstMixGroupSelected && MixSelectionLabels.Baseline}
            </Typography>
          </Grid>
          <Grid item xs={12} md className={classes.gridContainer}>
            <Typography>
              {isFirstMixGroupSelected && MixSelectionLabels.TestCategory}
            </Typography>
          </Grid>
          <Grid item xs={12} md className={classes.gridContainer}>
            <Typography>
              {isFirstMixGroupSelected && MixSelectionLabels.CarbonCure}
            </Typography>
          </Grid>
          <Grid item xs={12} md className={classes.gridContainer}>
            <Typography>
              {isFirstMixGroupSelected && MixSelectionLabels.TestCategory}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton style={{ pointerEvents: 'none' }}>
              <Icon />{' '}
              {/* Empty icon used to reserve space for the conditionally rendered delete icon */}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {mixSelectionRows.map((row, index) => (
          <Grid
            container
            direction="row"
            spacing={3}
            alignItems="flex-start"
            justify="space-between"
            key={row.id}
          >
            {row?.mixDesignId ? (
              <>
                <Grid item xs={12} md className={classes.gridContainer}>
                  <CommissionReportMixGroupSelect
                    index={index}
                    mixGroupOptions={mixGroupOptions}
                    mixDesignId={row.mixDesignId}
                    handleMixGroupChange={handleMixGroupChange}
                    getIsMixGroupOptionDisabled={getIsMixGroupOptionDisabled}
                    renderMixGroupOption={renderMixGroupOption}
                  />
                </Grid>
                <Grid item xs={12} md className={classes.gridContainer}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    id={`autocomplete-${index}`}
                    data-testid={`autocomplete-${index}`}
                    options={
                      mixSelections[index]?.variationOptions
                        ? mixSelections[index]?.variationOptions
                        : []
                    }
                    value={getSelectedVariation(
                      VariationTypes.BASELINE,
                      mixSelections[index]
                    )}
                    onChange={(_event, value) =>
                      handleVariationChange(
                        index,
                        value,
                        VariationTypes.BASELINE
                      )
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={MixSelectionLabels.MixVariation}
                        variant="outlined"
                        error={isVariationSampleCountInsufficient(
                          mixSelections,
                          variationsWithInsufficientSamplesList,
                          VariationTypes.BASELINE,
                          index
                        )}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                {getSelectedVariation(
                                  VariationTypes.BASELINE,
                                  mixSelections[index]
                                )?.label &&
                                  isVariationSampleCountInsufficient(
                                    mixSelections,
                                    variationsWithInsufficientSamplesList,
                                    VariationTypes.BASELINE,
                                    index
                                  ) && (
                                    <ReportProblemOutlinedIcon
                                      className={classes.errorIcon}
                                    />
                                  )}
                              </InputAdornment>
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    getOptionDisabled={option =>
                      getIsVariationOptionDisabled(
                        option,
                        index,
                        VariationTypes.BASELINE
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md className={classes.gridContainer}>
                  <Autocomplete
                    className={classes.multiSelectRoot}
                    disableClearable
                    size="small"
                    multiple
                    id={`multiselect-${index}`}
                    data-testid={`multiselect-${index}`}
                    options={getVariationTestCategoryOptions(
                      VariationTypes.BASELINE,
                      mixSelections[index]
                    )}
                    value={getBaselineSelectedTestCategories(
                      mixSelections[index]
                    )}
                    onChange={(_event, value) =>
                      handleTestCategoriesChange(
                        value,
                        index,
                        VariationTypes.BASELINE
                      )
                    }
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={
                          getBaselineSelectedTestCategories(
                            mixSelections[index]
                          )?.length > 0
                            ? ''
                            : MixSelectionLabels.TestCategory
                        }
                        variant="outlined"
                      />
                    )}
                    renderTags={values =>
                      renderTestCategoryTags(
                        values,
                        index,
                        VariationTypes.BASELINE
                      )
                    }
                    renderOption={option =>
                      renderTestCategoryOption(
                        option,
                        index,
                        VariationTypes.BASELINE
                      )
                    }
                    getOptionDisabled={option =>
                      getIsTestCategoryOptionDisabled(
                        option,
                        index,
                        VariationTypes.BASELINE
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md className={classes.gridContainer}>
                  <Autocomplete
                    disableClearable
                    size="small"
                    id={`autocomplete-${index}`}
                    data-testid={`autocomplete-${index}`}
                    options={
                      mixSelections[index]?.variationOptions
                        ? mixSelections[index]?.variationOptions
                        : []
                    }
                    value={getSelectedVariation(
                      VariationTypes.OPTIMIZED,
                      mixSelections[index]
                    )}
                    onChange={(_event, value) =>
                      handleVariationChange(
                        index,
                        value,
                        VariationTypes.OPTIMIZED
                      )
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={MixSelectionLabels.MixVariation}
                        variant="outlined"
                        error={isVariationSampleCountInsufficient(
                          mixSelections,
                          variationsWithInsufficientSamplesList,
                          VariationTypes.OPTIMIZED,
                          index
                        )}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                {getSelectedVariation(
                                  VariationTypes.OPTIMIZED,
                                  mixSelections[index]
                                )?.label &&
                                  isVariationSampleCountInsufficient(
                                    mixSelections,
                                    variationsWithInsufficientSamplesList,
                                    VariationTypes.OPTIMIZED,
                                    index
                                  ) && (
                                    <ReportProblemOutlinedIcon
                                      className={classes.errorIcon}
                                    />
                                  )}
                              </InputAdornment>
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    getOptionDisabled={option =>
                      getIsVariationOptionDisabled(
                        option,
                        index,
                        VariationTypes.OPTIMIZED
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md className={classes.gridContainer}>
                  <Autocomplete
                    className={classes.multiSelectRoot}
                    disableClearable
                    size="small"
                    multiple
                    id={`multiselect-${index}`}
                    data-testid={`multiselect-${index}`}
                    options={getVariationTestCategoryOptions(
                      VariationTypes.OPTIMIZED,
                      mixSelections[index]
                    )}
                    value={getCarbonCureSelectedTestCategories(
                      mixSelections[index]
                    )}
                    onChange={(_event, value) =>
                      handleTestCategoriesChange(
                        value,
                        index,
                        VariationTypes.OPTIMIZED
                      )
                    }
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder={
                          getCarbonCureSelectedTestCategories(
                            mixSelections[index]
                          )?.length > 0
                            ? ''
                            : MixSelectionLabels.TestCategory
                        }
                        variant="outlined"
                      />
                    )}
                    renderTags={values =>
                      renderTestCategoryTags(
                        values,
                        index,
                        VariationTypes.OPTIMIZED
                      )
                    }
                    renderOption={option =>
                      renderTestCategoryOption(
                        option,
                        index,
                        VariationTypes.OPTIMIZED
                      )
                    }
                    getOptionDisabled={option =>
                      getIsTestCategoryOptionDisabled(
                        option,
                        index,
                        VariationTypes.OPTIMIZED
                      )
                    }
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md className={classes.gridContainer}>
                  <CommissionReportMixGroupSelect
                    index={index}
                    mixGroupOptions={mixGroupOptions}
                    mixDesignId={row.mixDesignId}
                    handleMixGroupChange={handleMixGroupChange}
                    getIsMixGroupOptionDisabled={getIsMixGroupOptionDisabled}
                    renderMixGroupOption={renderMixGroupOption}
                  />
                </Grid>
                {/* The following filler grids are meant to mock the individual input spacing.
                            Grid spacing values (e.g. md={2}) can't be used, as the amount of inputs rendered per row 
                            do not evenly divide into the remaining grid spots. */}
                <Grid item xs={12} md className={classes.gridContainer}></Grid>
                <Grid item xs={12} md className={classes.gridContainer}></Grid>
                <Grid item xs={12} md className={classes.gridContainer}></Grid>
                <Grid item xs={12} md className={classes.gridContainer}></Grid>
              </>
            )}
            {getIsDeleteIconVisible(row) ? (
              <Grid item alignItems="center" style={{ marginTop: '12px' }}>
                <IconButton
                  onClick={() => handleDeleteMixClick(index)}
                  data-testid={`deleteMixSelection-${index}`}
                >
                  <DeleteOutlined style={{ color: 'rgb(215, 79, 57)' }} />
                </IconButton>
              </Grid>
            ) : (
              <Grid item>
                <IconButton style={{ pointerEvents: 'none' }}>
                  <Icon />{' '}
                  {/* Empty icon used to reserve space for the conditionally rendered delete icon */}
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          spacing={3}
          alignItems="flex-start"
          justify="space-between"
          style={{ marginTop: '12px' }}
        >
          <Grid item xs={12} sm={6}>
            <Tooltip title={getAddMixDisabledTooltip()}>
              <span>
                <Button
                  data-testid={`addMixSelection`}
                  color="primary"
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={handleAddMixClick}
                  disabled={getIsAddMixBtnDisabled()}
                >
                  <Typography>
                    <strong>Add Mix</strong>
                  </Typography>
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MixSelection
