import React from 'react'
import {
  addMinutes,
  parseISO,
  formatISO,
  format,
  parse,
  subDays,
  isAfter,
  isBefore,
  isEqual,
  startOfDay,
} from 'date-fns'
import {
  DateRange,
  SonarFilterType,
  SonarDateRange,
} from '../../../TSS/Logic/Types'
import { ISonarDownSystem } from '../../../Common/Logic/Types'
import { getLocalTimezoneOffsetFromUTC } from '../../../Common/Helpers/GeneralHelpers'
import theme from '../../../theme/muiTheme'

/**
 * Converts a UTC ISO string to a local time ISO string with the appropriate time zone offset.
 * @param {string} utcISODate - The UTC ISO string to be converted.
 * @returns {string} The local time ISO string with the time zone offset.
 */
export const convertUTCISOToLocalISO = (
  utcISODate: string | null
): string | null => {
  if (!utcISODate) return null
  const utcDate = parseISO(utcISODate)
  const localDate = addMinutes(utcDate, new Date().getTimezoneOffset())
  const localISOString = formatISO(localDate)

  return localISOString
}

/**
 * Formats an ISO date string to 'yyyy-MM-dd' format.
 * @param {string} ISODate - The ISO date string to be formatted.
 * @returns {string | null} The formatted date string or null if input is invalid.
 */
export const formatISODate = (ISODate: string | null) => {
  if (!ISODate) return null

  const parsedDate = parseISO(ISODate)
  const formattedDate = format(parsedDate, 'yyyy-MM-dd')

  return formattedDate
}

/**
 * Converts a formatted date string (yyyy-MM-dd) to a complete local ISO string.
 * @param {string} date - The formatted date string to be converted.
 * @returns {string} The complete local ISO date string.
 */
export const convertFormattedDateToISO = (date: string) => {
  const parsedDate = parse(date, 'yyyy-MM-dd', new Date())
  const localISODateString = formatISO(parsedDate, {
    representation: 'complete',
  })

  return localISODateString
}

/**
 * Gets the proper default date range based on the type of filter using the custom date range component
 * @param {SonarFilterType | null} filterType - The type of filter, based on alarms
 * @param {DateRange} dateStart - The date string in ISO format
 * @param {DateRange} dateEnd - The date string in ISO format
 * @returns {SonarDateRange | null} The selected date range
 */
export const getDefaultSelectedRange = (
  filterType: SonarFilterType | null | undefined,
  dateStart: DateRange,
  dateEnd: DateRange
) => {
  if (dateStart || dateEnd) return SonarDateRange.CustomRange

  switch (filterType) {
    case SonarFilterType.Alarms:
      return SonarDateRange.Last7Days
    case SonarFilterType.Snoozed:
      return SonarDateRange.Last180Days
    default:
      return null
  }
}

/**
 * Gets the proper default start date based on the type of filter using the custom date range component
 * @param {SonarFilterType | null} filterType - The type of filter, based on alarms
 * @returns {string} The date string in ISO format
 */
export const getDefaultStartDate = (
  filterType: SonarFilterType | null | undefined
) => {
  const newDate = new Date().toISOString()
  if (!filterType) return newDate
  const last7Days = subDays(new Date(), 7)
  const last180Days = subDays(new Date(), 180)
  switch (filterType) {
    case SonarFilterType.Alarms:
      return last7Days.toISOString()
    case SonarFilterType.Snoozed:
      return last180Days.toISOString()
    default:
      return newDate
  }
}

/**
 * Gets the proper default input label based on the type of filter using the custom date range component
 * @param {SonarFilterType | null} filterType - The type of filter, based on alarms
 * @returns {string} The custom date range component label
 */
export const getLabelText = (
  filterType: SonarFilterType | null | undefined
) => {
  switch (filterType) {
    case SonarFilterType.Down:
      return 'Last Seen Date Range'
    case SonarFilterType.Snoozed:
      return 'Date Snoozed'
    case SonarFilterType.DownSnoozedModal:
      return 'Snooze For*'
    case SonarFilterType.AlarmSnoozedModal:
      return 'Snooze For*'
    default:
      return 'Date Range'
  }
}

/**
 *
 * @param range { SonarDateRange } Selected SonarDateRange value
 * @param startDateSelected { string | null } Selected start date
 * @param endDateSelected { string | null } Selected end date
 * @returns { string } Label for the selected date range
 */
export const getLabelForDateRange = (
  tabValue: number,
  range: string | null,
  startDateSelected?: string | null,
  endDateSelected?: string | null
): string => {
  const isAlarmsTabSelected = tabValue === 1
  const property = isAlarmsTabSelected ? 'Date Range' : 'Last Seen'
  switch (range) {
    case SonarDateRange.All:
      return `${property}: All Time`
    case SonarDateRange.Today:
      return `${property}: Today`
    case SonarDateRange.Yesterday:
      return `${property}: Yesterday`
    case SonarDateRange.Last7Days:
      return `${property}: Last 7 Days`
    case SonarDateRange.Last30Days:
      return `${property}: Last 30 Days`
    case SonarDateRange.Last90Days:
      return `${property}: Last 90 Days`
    case SonarDateRange.Last180Days:
      return `${property}: Last 180 Days`
    case SonarDateRange.CustomRange:
      return `${property} Custom Range: ${startDateSelected} to ${endDateSelected}`
    default:
      return ''
  }
}

/* Our digested property of "snoozed" returns a string, as it is primarily used
for sending URL params. This converts it into a boolean.
*/
export const isSystemSnoozed = (clickedDownSystem: ISonarDownSystem | null) => {
  return clickedDownSystem?.snoozed && /^true$/i.test(clickedDownSystem.snoozed)
}

/* Used for Button enabling validation. Checks if a date is within a specific 
range
*/
export const isDateWithinRange = (
  date: string | null,
  minDate: string,
  maxDate: string
): boolean => {
  if (!date) return false
  const parsedDate = parseISO(date.substring(0, 10))
  const parsedMinDate = parseISO(minDate)
  const parsedMaxDate = parseISO(maxDate)
  const today = startOfDay(new Date())

  return (
    isEqual(parsedDate, today) ||
    ((isAfter(parsedDate, parsedMinDate) ||
      isEqual(parsedDate, parsedMinDate)) &&
      (isBefore(parsedDate, parsedMaxDate) ||
        isEqual(parsedDate, parsedMaxDate)))
  )
}

export const createFormattedLastSeenHeader = (
  isUTC: boolean,
  timestampName: string
): React.ReactNode => {
  const localTimezoneOffset = getLocalTimezoneOffsetFromUTC()
  return (
    <>
      {timestampName} <br />
      <span
        style={{
          // @ts-ignore
          ...theme.typography.headerInfo,
        }}
      >
        ({isUTC ? 'UTC - UTC+00:00' : `Local - UTC${localTimezoneOffset}`})
      </span>
    </>
  )
}

export const getCommonTableHeadCells = (
  isUTC: boolean,
  timestampId: string,
  timestampName: string,
  timestampTooltip: string,
  technologyTypeId: string,
  additionalCells: any[] = []
) => {
  const headCells = [
    {
      align: 'center',
      id: 'snoozeIcon',
      name: '',
      sortable: false,
      tooltip: '',
      width: '1px',
    },
    {
      align: 'left',
      id: timestampId,
      name: createFormattedLastSeenHeader(isUTC, timestampName),
      sortable: true,
      tooltip: timestampTooltip,
      width: '200px',
    },
    {
      align: 'left',
      id: 'systemId',
      name: 'System ID',
      sortable: true,
      width: '160px',
    },
    {
      align: 'left',
      id: technologyTypeId,
      name: 'Tech.',
      sortable: true,
      tooltip: 'Technology',
      width: '40px',
    },
    {
      align: 'left',
      id: 'divisionName',
      name: 'Producer',
      sortable: true,
      width: '320px',
    },
    {
      align: 'left',
      id: 'plantName',
      name: 'Plant',
      sortable: true,
      width: '200px',
    },
    {
      align: 'left',
      id: 'location',
      name: 'Location',
      sortable: true,
      width: '200px',
    },
    ...additionalCells,
    {
      align: 'left',
      id: 'action',
      name: '',
      sortable: false,
      width: '50px',
    },
  ]
  return headCells
}

export const getUTCDateBounds = (currentDate: Date = new Date()) => {
  const currentDateUTC = new Date(currentDate)
  currentDateUTC.setUTCHours(0, 0, 0, 0) // Set to midnight UTC

  // Set the cutoff as currentDateUTC + 1  at midnight
  const cutoffDate = new Date(currentDateUTC)
  cutoffDate.setUTCDate(cutoffDate.getUTCDate() + 1)
  cutoffDate.setUTCHours(0, 0, 0, 0)

  return { currentDateUTC, cutoffDate }
}
