import React, { Dispatch, SetStateAction, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  CommissionReportStatus,
  ICommissionReportSettings,
  IInsufficientMaturityAgeSamples,
  IInsufficientVariationSamples,
  IMixSelection,
} from '../Logic/Types'
import ViewCommissionReportMixDesignDetails from './ViewCommissionReportMixDesignDetails'
import { atomJWT } from '../../Common/atoms'
import EditModeAlert from '../Components/EditModeAlert'
import { getDate } from '../../Common/Helpers/GeneralHelpers'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ViewCommissionReportOutliersCard from './ViewCommissionReportOutliersCard'
import ViewMixSelectionTable from '../Components/CommissionReportGenerator/ViewMixSelectionTable'
import ViewCommissionReportConclusion from '../Components/CommissionReportGenerator/ViewCommissionReportConclusion'
import CommissionReportTestResults from '../Components/CommissionReportGenerator/CommissionReportTestResults'
import InsufficientSamplesBanner from '../Components/CommissionReportGenerator/InsufficientSamplesBanner'
import { jsonUnescape } from '../Helpers/CommissionReportHelpers'
import { ICommissionReportCustomer } from './CommissionReportGeneratorView'

export interface IViewCommissionReportProps {
  mixSelections: IMixSelection[]
  reportSettings: ICommissionReportSettings
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  isCommissionReportViewMode: boolean
  setIsCommissionReportViewMode: Dispatch<SetStateAction<boolean>>
  companyLogoUrl?: string
  isValidReportId: boolean
  variationsWithInsufficientSamplesList: IInsufficientVariationSamples[]
  variationsWithInsufficientIntervalSamples: IInsufficientMaturityAgeSamples
  isLoadingMixData: boolean
  setIsLoadingMixData: Dispatch<SetStateAction<boolean>>
  selectedCustomer: ICommissionReportCustomer
  isPrinting: boolean
}

const useStyles = makeStyles(theme => ({
  sectionContainer: {
    margin: '24px 10px 0px 10px',
    borderRadius: '8px',
    fontSize: '22px',
    minHeight: '20px',
  },
  subSectionContainer: {
    padding: '16px 24px',
    marginTop: '24px',
  },
  backButton: {
    marginTop: '18px',
    marginLeft: '-12px',
  },
  arrowIcon: {
    fontSize: '20px',
    marginRight: '8px',
  },
  pdfSectionContainer: {
    margin: '0px 10px 0px 10px',
    borderRadius: '8px',
    fontSize: '22px',
    minHeight: '20px',
    paddingBottom: '4px',
  },
  pdfSectionContainerWithTopMargin: {
    margin: '56px 10px 0px 10px',
    borderRadius: '8px',
    fontSize: '22px',
    minHeight: '20px',
  },
  pdfSubSectionContainer: {
    padding: '16px 24px 16px 24px',
    marginTop: '0px',
  },
  pdfSubSectionContainerWithoutTopPadding: {
    padding: '0px 24px 16px 24px',
    marginTop: '0px',
  },
  dividerAfterOutliers: {
    padding: '0px 18px 0px 8px !important',
    marginTop: 32,
    marginBottom: 24,
  },
  dividerAfterGraph: {
    padding: '0px 18px 0px 8px !important',
    marginTop: 8,
    marginBottom: 24,
  },
  mb24: {
    marginBottom: '24px',
  },
}))

function ViewCommissionReport(props: Readonly<IViewCommissionReportProps>) {
  const {
    reportSettings,
    setReportSettings,
    mixSelections,
    isCommissionReportViewMode,
    setIsCommissionReportViewMode,
    companyLogoUrl,
    isValidReportId,
    variationsWithInsufficientSamplesList,
    variationsWithInsufficientIntervalSamples,
    selectedCustomer,
    isPrinting,
  } = props
  const [redirect, setRedirect] = useState(false)
  const [isCommissionReport] = useState(true)
  const classes = useStyles()
  const JWT = useRecoilValue(atomJWT)
  const producerName = selectedCustomer.divisionName
  const author = isValidReportId
    ? reportSettings.lastUpdatedBy
    : reportSettings.createdBy
  const date = reportSettings ? reportSettings.lastUpdated : getDate(Date.now())
  const mixCode = mixSelections?.[0]?.mixCode ?? ''
  const location = selectedCustomer.plantName

  const reportReviewed =
    reportSettings?.reportStatus === CommissionReportStatus.Reviewed

  const handleBackClick = () => {
    setRedirect(true)
  }

  if (redirect) {
    return <Redirect to="/Producers/ReportLibrary" />
  }

  const formattedTestingSummary = jsonUnescape(
    reportSettings?.testingSummary
  ).split('\n')

  const getOutlierSectionClass = (index: number) => {
    const showAvgStrengthGraph =
      reportSettings.mixDesignSettings[index].avgStrengthGraph.isSelected
    const showFrequencyGraph =
      reportSettings.mixDesignSettings[index].frequencyGraph.isSelected
    if (isPrinting && showAvgStrengthGraph && showFrequencyGraph) {
      return classes.pdfSubSectionContainer
    } else if (isPrinting && !(showAvgStrengthGraph && showFrequencyGraph)) {
      return classes.pdfSubSectionContainerWithoutTopPadding
    } else {
      return classes.subSectionContainer
    }
  }

  const getDividerClass = (index: number) => {
    const showOutlierSection =
      reportSettings.mixDesignSettings[index].outlierSettings.show
    if (showOutlierSection) {
      return classes.dividerAfterOutliers
    } else {
      return classes.dividerAfterGraph
    }
  }

  return (
    <div>
      {!reportReviewed && !isPrinting && (
        <Grid container direction="column" justify="flex-start" spacing={3}>
          <EditModeAlert
            roles={JWT.roles}
            isCommissionReportViewMode={isCommissionReportViewMode}
            setIsCommissionReportViewMode={setIsCommissionReportViewMode}
            isCommissionReport={isCommissionReport}
            producerName={producerName}
            mixCode={mixCode}
            location={location}
          />
        </Grid>
      )}
      {!isPrinting && (
        <Button
          className={classes.backButton}
          color="primary"
          onClick={handleBackClick}
          style={{ marginBottom: '12px', textTransform: 'none' }}
        >
          <ArrowBackIcon className={classes.arrowIcon} />
          Back to Report Library
        </Button>
      )}
      <Grid
        container
        direction="column"
        justify="flex-start"
        spacing={3}
        style={{ marginBottom: '24px', marginTop: '24px' }}
      >
        {!isPrinting && (
          <Paper
            elevation={0}
            className={classes.sectionContainer}
            style={{ paddingBottom: 0, marginTop: '0px' }}
          >
            <ViewMixSelectionTable
              mixSelections={mixSelections}
              reportSettings={reportSettings}
              variationsWithInsufficientSamplesList={
                variationsWithInsufficientSamplesList
              }
            />
          </Paper>
        )}
      </Grid>
      {!isPrinting && (
        <>
          <Typography
            variant="body2"
            style={{ margin: '8px 0px', marginTop: '8px' }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {isValidReportId ? 'Last Updated By: ' : 'Created By: '}
            </span>
            {`${author} on ${date}`}
          </Typography>
          <Grid container justify="space-between">
            <Typography variant="h2" style={{ marginBottom: '8px' }}>
              {`CarbonCure Commissioning Report - ${producerName}`}
            </Typography>
            {companyLogoUrl && (
              <img
                src={companyLogoUrl}
                alt="company logo"
                style={{ width: '100px', height: '30px', objectFit: 'contain' }}
              />
            )}
          </Grid>
        </>
      )}
      <Grid container spacing={3}>
        {!isPrinting && variationsWithInsufficientSamplesList.length > 0 && (
          <InsufficientSamplesBanner
            variationsWithInsufficientSamples={
              variationsWithInsufficientSamplesList
            }
            bannerType="default"
            reportSettings={reportSettings}
          />
        )}
      </Grid>
      <Grid
        container
        direction="column"
        justify="flex-start"
        spacing={3}
        style={{ marginBottom: '24px' }}
      >
        <Paper
          elevation={1}
          className={
            isPrinting
              ? classes.pdfSectionContainerWithTopMargin
              : classes.sectionContainer
          }
        >
          {mixSelections && mixSelections.length > 0 && (
            <ViewCommissionReportMixDesignDetails
              mixSelections={mixSelections}
              reportSettings={reportSettings}
              setReportSettings={setReportSettings}
              isPrinting={isPrinting}
            />
          )}
        </Paper>
        {reportSettings.testingSummary && (
          <Paper
            elevation={0}
            className={
              isPrinting
                ? classes.pdfSectionContainer
                : classes.sectionContainer
            }
          >
            <Typography variant="h3">Testing Summary</Typography>
            <Typography variant="body1">
              {formattedTestingSummary.map((text: string, index: number) => (
                <Typography key={index + text} variant="body1">
                  {text}
                </Typography>
              ))}
            </Typography>
          </Paper>
        )}
        {reportSettings.conclusionParagraph && (
          <Paper
            elevation={0}
            className={
              isPrinting
                ? classes.pdfSectionContainer
                : classes.sectionContainer
            }
          >
            <ViewCommissionReportConclusion reportSettings={reportSettings} />
          </Paper>
        )}
      </Grid>
      <Grid container spacing={3}>
        {!isPrinting &&
          Object.keys(variationsWithInsufficientIntervalSamples).length > 0 && (
            <InsufficientSamplesBanner
              variationsWithInsufficientIntervalSamples={
                variationsWithInsufficientIntervalSamples
              }
              bannerType="interval"
              reportSettings={reportSettings}
            />
          )}
      </Grid>
      {mixSelections.map((mixSelection, index) => (
        <Grid
          container
          direction="column"
          justify="flex-start"
          spacing={3}
          className={mixSelections.length > 1 ? 'page-break' : undefined}
          style={{
            marginTop: isPrinting && mixSelections.length > 1 ? '90px' : 0,
          }}
          key={mixSelection.mixDesignId}
        >
          <Paper
            elevation={0}
            className={
              isPrinting
                ? classes.pdfSectionContainer
                : classes.sectionContainer
            }
          >
            <Typography variant="h3" style={{ marginBottom: '24px' }}>
              Test Results{' '}
              <span style={{ fontWeight: 'normal' }}>
                <Typography
                  component="h4"
                  variant="h4"
                  display="inline"
                  style={{ marginLeft: '16px' }}
                >
                  {mixSelection.mixCode}
                </Typography>
              </span>
            </Typography>
            <CommissionReportTestResults
              mixSelections={mixSelections}
              reportSettings={reportSettings}
              setReportSettings={setReportSettings}
              tabValue={index}
              parentClasses={classes}
              isCommissionReportViewMode={isCommissionReportViewMode}
              isPrinting={isPrinting}
            />
          </Paper>
          <Paper elevation={0} className={getOutlierSectionClass(index)}>
            <ViewCommissionReportOutliersCard
              reportSettings={reportSettings}
              mixSelection={mixSelection}
              showOutliers={
                reportSettings.mixDesignSettings[index].outlierSettings.show
              }
            />
          </Paper>
          {!isPrinting &&
            mixSelections.length > 0 &&
            index !== mixSelections.length - 1 && (
              <Grid item xs={12} className={getDividerClass(index)}>
                <Divider />
              </Grid>
            )}
        </Grid>
      ))}
    </div>
  )
}

export default ViewCommissionReport
