import { useEffect, useRef, useCallback } from 'react'
//@ts-ignore FUTURE: Define the type for the react-router-dom package
import { useLocation, useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { tabsConfig } from './SonarTabHelper'
import { getSonarFeatureFlags } from '../../../Common/Helpers/GeneralHelpers'
import { ISonarFeatureFlags } from '../../../Common/Logic/Types'
import {
  atomSonarFrontendFilter,
  atomSonarTabValue,
} from '../../../Common/atoms'
import SonarViewHelper from '../Views/SonarViewHelper'

const useSonarTabs = () => {
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const tabQueryParam = query.get('tab')
  const [tabValue, setTabValue] = useRecoilState(atomSonarTabValue)
  const isUpdatingRef = useRef(false)
  const setSonarFrontendFilter = useSetRecoilState(atomSonarFrontendFilter)

  const featureFlags = getSonarFeatureFlags()
  const enabledTabs = tabsConfig.filter(
    tab => featureFlags[tab.flag as keyof ISonarFeatureFlags]
  )

  const getInitialTabValue = useCallback(() => {
    const initialTabIndex = enabledTabs.findIndex(
      tab => tab.urlLabel === tabQueryParam
    )
    return initialTabIndex >= 0 ? initialTabIndex : 0
  }, [enabledTabs, tabQueryParam])

  const onTabChange = useCallback(
    (_event: React.ChangeEvent<{}>, value: number) => {
      setTabValue(value)
    },
    [setTabValue]
  )

  useEffect(() => {
    setTabValue(tabValue)
    SonarViewHelper.tabSwitchingHandler(setSonarFrontendFilter, true)
  }, [setTabValue, tabValue, setSonarFrontendFilter])

  useEffect(() => {
    if (isUpdatingRef.current) {
      isUpdatingRef.current = false
      return
    }

    const syncTabWithUrl = () => {
      const currentQueryParams = new URLSearchParams(location.search)
      //@ts-ignore optional chaining is used to prevent errors
      const newUrlTab = enabledTabs[tabValue]?.urlLabel

      if (newUrlTab) {
        isUpdatingRef.current = true
        // Preserve existing parameters and set the 'tab' parameter
        currentQueryParams.set('tab', newUrlTab)
        history.replace({
          pathname: location.pathname,
          search: currentQueryParams.toString(),
        })
      }
    }

    syncTabWithUrl()
  }, [tabValue, enabledTabs, history, location.pathname, location.search])

  // Set initial tabValue
  useEffect(() => {
    setTabValue(getInitialTabValue())
  }, [setTabValue, getInitialTabValue])

  return {
    enabledTabs,
    onTabChange,
    tabValue,
  }
}

const hooks = {
  useSonarTabs,
}

export default hooks
