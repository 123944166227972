import React from 'react'
//@ts-ignore FUTURE: Define the type for the react-helmet package
import { Helmet } from 'react-helmet'
import {
  Backdrop,
  Box,
  Container,
  Grid,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import FilterButton from '../../../Common/Components/FilterPanel/FilterButton'
import FilterPanelChips from '../../../Common/Components/FilterPanel/FilterPanelChips'
import FilterPanelLogical from '../../../Common/Components/FilterPanel/FilterPanelLogical'
import SonarTabs from '../Components/SonarTabs'
import { sonarDescription } from '../Constants/SonarConstants'
import hooks from './useSonarView'
import { SonarDateRange, SonarFilterType } from '../../../TSS/Logic/Types'
import { useRecoilValue } from 'recoil'
import { atomSonarTabValue } from '../../../Common/atoms'
import SonarViewHelper from './SonarViewHelper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewTitle: {
      marginBottom: '0.5em',
    },
    viewSubTitle: {
      ...theme.typography.subtitle1,
      marginBottom: '1em',
    },
    container: {
      padding: '0px 8px',
      position: 'relative',
    },
    backDropZIndex: {
      zIndex: 50,
    },
    // @ts-ignore (For future resolution)
    ...theme.customClasses.filterPanel,
    filterPanelOpen: {
      // @ts-ignore (For future resolution)
      ...theme.customClasses.filterPanel.filterPanelOpen,
      top: '150px',
      zIndex: 50,
      [theme.breakpoints.up(1340)]: {
        top: '128px',
      },
    },
    filterPanel: {
      // @ts-ignore (For future resolution)
      ...theme.customClasses.filterPanel.filterPanel,
      zIndex: -50,
      top: '150px',
      [theme.breakpoints.up(1340)]: {
        top: '128px',
      },
    },
  })
)

const useFilterStyles = makeStyles({
  checkbox: {
    marginLeft: 'auto',
  },
  checkboxFormControlLabel: {
    marginLeft: 'auto',
    width: '100%',
  },
})

function SonarView() {
  const classes: ClassNameMap = useStyles()
  const filtersClasses = useFilterStyles()

  // Hook
  const {
    activeFilters,
    addDataSettings,
    clearRangeSelected,
    endDateSelected,
    expandFilters,
    filterButtonClickHandler,
    filterCount,
    filterPanelChipsButtonClickHandler,
    filterPanelChipsChipClickHandler,
    filterPanelHelperVariables,
    filterPanelItems,
    getFilterValue,
    isFilterOpen,
    isMenuOpenChangeHandler,
    rangeSelected,
    setAddDataSettings,
    setEndDateSelected,
    setExpandFilters,
    setIsFilterOpen,
    setRangeSelected,
    setStartDateSelected,
    setSonarFrontendFilter,
    startDateSelected,
    isLoading,
  } = hooks.useSonarView()

  const tabValue = useRecoilValue(atomSonarTabValue)

  return (
    <>
      <Helmet>
        <title>Sonar</title>
      </Helmet>

      <Backdrop open={isLoading} className={classes.backDropZIndex}>
        <CircularProgress color="primary" />
      </Backdrop>

      <Container
        maxWidth="xl"
        className={classes.container}
        id="sonar-container"
      >
        <Grid item>
          <FilterPanelLogical
            //@ts-ignore (For future resolution)
            addDataSettings={addDataSettings}
            expandFilters={expandFilters}
            //@ts-ignore (For future resolution)
            filterPanel={filterPanelItems}
            //@ts-ignore (For future resolution)
            getValue={getFilterValue}
            //@ts-ignore (For future resolution)
            helperVariables={filterPanelHelperVariables}
            open={isFilterOpen}
            parentClasses={classes}
            setExpandFilters={setExpandFilters}
            //@ts-ignore (For future resolution)
            setOpen={setIsFilterOpen}
            //@ts-ignore (For future resolution)
            setAddDataSettings={setAddDataSettings}
            dateRangeSettings={{
              clearRangeSelected: clearRangeSelected,
              dateRangeStart: startDateSelected,
              dateRangeEnd: endDateSelected,
              menuStatusChangeHandler: isMenuOpenChangeHandler,
              tabStatusChangeHandler: SonarViewHelper.tabSwitchingHandler,
              rangeSelected: rangeSelected as SonarDateRange,
              setEndDateSelected: setEndDateSelected,
              setRangeSelected: setRangeSelected,
              setStartDateSelected: setStartDateSelected,
              setSonarFrontendFilter: setSonarFrontendFilter,
              sonarDateRangeFilterType:
                tabValue === 1 ? SonarFilterType.Alarms : SonarFilterType.Down,
            }}
            filtersClasses={filtersClasses}
          />
        </Grid>

        <Box
          className={
            isFilterOpen ? classes.wrapperShifted : classes.wrapperUnshifted
          }
        >
          <Grid container direction="column">
            <Grid item xs>
              <Typography variant="h2" className={classes.viewTitle}>
                Sonar
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="subtitle1" className={classes.viewSubTitle}>
                {sonarDescription}
              </Typography>
            </Grid>
            <Grid container item justifyContent="space-between">
              <Grid item className={classes.buttonOverride}>
                <FilterButton
                  filterCount={filterCount}
                  onClickHandler={filterButtonClickHandler}
                  open={false}
                  parentClasses={classes}
                />
                <FilterPanelChips
                  activeFilters={activeFilters}
                  buttonClickHandler={filterPanelChipsButtonClickHandler}
                  chipClickHandler={filterPanelChipsChipClickHandler}
                  buttonText="Reset Filters to Default"
                />
              </Grid>
            </Grid>

            <Grid item xs>
              {/* Pass the tabValue and setTabValue to the SonarTabs component */}
              <SonarTabs isFilterOpen={isFilterOpen} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default SonarView
