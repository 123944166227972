import React, { useEffect, useState } from 'react'
import { Filter } from '../../Logic/Types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

interface IFilterPanelAccordionProps {
  renderElement: (filter: Filter) => JSX.Element | undefined
  filter: Filter[]
  expanded: boolean
}

const useStyles = makeStyles({
  accordion: {
    paddingLeft: 0,
    paddingRight: '12px',
    margin: '0',
  },
  accordionDetails: {
    paddingLeft: '0',
    margin: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

const FilterPanelAccordion = ({
  renderElement,
  filter,
  expanded,
}: IFilterPanelAccordionProps) => {
  const classes = useStyles()
  const [isExpanded, setIsExpanded] = useState(expanded)

  // Synchronize local state with the `expanded` prop
  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  // Handle manual toggle
  const handleToggle = () => {
    setIsExpanded((prev: boolean) => !prev)
  }

  return (
    <div>
      <Accordion elevation={0} expanded={isExpanded} onChange={handleToggle}>
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls="panel1a-content"
          id="filter-accordion"
          data-testid="accordion-filter"
          className={classes.accordion}
        >
          <Typography>{filter[0]?.heading || filter[0]?.name}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {filter.map(filter => (
            <div key={filter.property}>{renderElement(filter)}</div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default FilterPanelAccordion
