import { useCallback, useEffect, useState } from 'react'
//@ts-ignore (For future resolution)
import { useLocation, useHistory } from 'react-router-dom'
import {
  Filter,
  FilterComponentTypes,
  FilterOption,
  IActiveFilter,
  ISonarFilterOption,
  ICustomerFilterOptions,
  ISonarFilter,
  ISonarFrontendFilter,
} from '../../../Common/Logic/Types'
import {
  DefaultFilterSectionExpanded,
  SonarDateRange,
} from '../../../TSS/Logic/Types'
import {
  defaultCustomerFilterOptions,
  alarmSeverityOptions,
  snoozeStatusOptions,
  technologyTypes,
  timeDownOptions,
} from '../Constants/SonarConstants'
import SonarViewHelper from './SonarViewHelper'
import { useRecoilState } from 'recoil'
import { atomSonarFilter, atomSonarFrontendFilter } from '../../../Common/atoms'
import { getNewSettings } from '../../../TSS/Data/TSSDataHelpers'
import {
  formatDateObjectToString,
  getLocalTimezoneOffsetFromUTC,
} from '../../../Common/Helpers/GeneralHelpers'
import { useSonarTabValue } from '../Components/useSonarTabValue'
import { getUTCDateBounds } from '../Helpers/SonarHelpers'
import sonarDateRangeSelectHelper from '../Components/SonarDateRangeSelectHelper'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IUseSonarViewProps {}

const useSonarView = () => {
  const location = useLocation()
  const history = useHistory()
  const tabValue = useSonarTabValue()

  // State and Setters
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isFirstRender, setIsFirstRender] = useState(true)

  /** Handles displayed filter count */
  const [filterCount, setFilterCount] = useState<number>(0)

  /** Handles opening and closing of Filter Drawer */
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  /** Handles active filters */
  const [activeFilters, setActiveFilters] = useState<IActiveFilter[]>([])

  /** Handles expanded filters */
  const [expandFilters, setExpandFilters] = useState<
    DefaultFilterSectionExpanded
  >({
    technology: false,
    customer: false,
    downSystems: false,
  })

  /** Sonar Filters */
  const [sonarFilter, setSonarFilter] = useRecoilState<ISonarFilter>(
    atomSonarFilter
  )

  const [sonarFrontendFilter, setSonarFrontendFilter] = useRecoilState<
    ISonarFrontendFilter
  >(atomSonarFrontendFilter)

  /** Handles custom date range settings */
  const [rangeSelected, setRangeSelected] = useState<string | null>(
    sessionStorage.getItem('sonar-lastSeenDateRange') ?? null
  )

  const [startDateSelected, setStartDateSelected] = useState<string | null>(
    sessionStorage.getItem('sonar-lastSeenStartDate') ?? null
  )

  const [endDateSelected, setEndDateSelected] = useState<string | null>(
    sessionStorage.getItem('sonar-lastSeenEndDate') ?? null
  )

  const [clearRangeSelected, setClearRangeSelected] = useState<boolean>(false)

  const [addDataSettings, setAddDataSettings] = useState([])
  const [customerFilterOptions, setCustomerFilterOptions] = useState<
    ICustomerFilterOptions
  >(defaultCustomerFilterOptions)
  const [alarmTypeOptions, setAlarmTypeOptions] = useState<FilterOption[]>([])

  const { search } = location

  // CALLBACKS
  /** Updates the URL with tab and filter parameters */
  const updateUrlParams = useCallback(() => {
    const currentQueryParams = new URLSearchParams(search)

    // Retrieve the current tab parameter
    const tabParam = currentQueryParams.get('tab')

    // Start fresh for updating
    const updatedQueryParams = new URLSearchParams()

    // Retain 'tab' in updated query params if it exists
    if (tabParam) {
      updatedQueryParams.set('tab', tabParam)
    }

    // Add filter parameters from sonarFilter state
    Object.keys(sonarFilter).forEach(key => {
      const filterValues = sonarFilter[key as keyof ISonarFilter]
      if (filterValues && filterValues.length > 0) {
        filterValues.forEach((value: string) => {
          updatedQueryParams.append(key, value)
        })
      }
    })

    // Add filter parameters from sonarFilter state
    Object.keys(sonarFrontendFilter).forEach(key => {
      const filterValues =
        sonarFrontendFilter[key as keyof ISonarFrontendFilter]
      if (filterValues && filterValues.length > 0) {
        filterValues.forEach((value: string) => {
          updatedQueryParams.append(key, value)
        })
      }
    })

    // Replace the URL with updated query parameters
    history.replace({
      pathname: location.pathname,
      search: `?${updatedQueryParams.toString()}`,
    })
  }, [search, history, location.pathname, sonarFilter, sonarFrontendFilter])

  // Callbacks
  const filterButtonClickHandler = useCallback(() => {
    setIsFilterOpen(!isFilterOpen)
  }, [isFilterOpen, setIsFilterOpen])

  /** Click handler for the individual filter item chips */
  const filterPanelChipsChipClickHandler = useCallback(
    (activeFilter: IActiveFilter) => {
      switch (activeFilter.id) {
        case 'technology-all': {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = {
              ...prevSonarFilter,
            }
            tempSonarFilter.technology = []
            return tempSonarFilter
          })
          break
        }

        case 'date-range': {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = {
              ...prevSonarFilter,
            }
            setRangeSelected(null)
            tempSonarFilter.lastSeenDateRange = []
            tempSonarFilter.lastSeenStartDate = []
            tempSonarFilter.lastSeenEndDate = []
            return tempSonarFilter
          })
          break
        }

        case 'time-down': {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = {
              ...prevSonarFilter,
            }
            tempSonarFilter.timeDown = []
            tempSonarFilter.lastSeenStartDate = []
            tempSonarFilter.lastSeenEndDate = []
            return tempSonarFilter
          })
          break
        }

        default: {
          setSonarFilter(prevSonarFilter => {
            const tempSonarFilter = {
              ...prevSonarFilter,
            }
            // For technology related filter chips
            tempSonarFilter.technology = tempSonarFilter.technology.filter(
              tech => tech !== activeFilter.id.split('-')[1]
            )

            // For customer related filter chips
            tempSonarFilter[activeFilter.id.split('-')[1]] = []

            // For alarm snooze status filter chips
            tempSonarFilter.snoozeStatus = tempSonarFilter.snoozeStatus.filter(
              (status: string) => status !== activeFilter.id.split('-')[1]
            )
            // Update shouldShowSnoozed and shouldShowUnsnoozed at the same time as snoozeStatus to avoid multiple table requests being sent
            tempSonarFilter.shouldShowSnoozed = [
              tempSonarFilter.snoozeStatus?.includes('shouldShowSnoozed')
                ? 'true'
                : 'false',
            ]
            tempSonarFilter.shouldShowUnsnoozed = [
              tempSonarFilter.snoozeStatus?.includes('shouldShowUnsnoozed')
                ? 'true'
                : 'false',
            ]

            // For alarm severity filter chips
            tempSonarFilter.alarmSeverity = tempSonarFilter?.alarmSeverity?.filter(
              (severity: string) => severity !== activeFilter.id.split('-')[1]
            )

            // For alarm type filter chips
            tempSonarFilter.alarmType = tempSonarFilter?.alarmType?.filter(
              (typeId: string) => typeId !== activeFilter.id.split('-')[1]
            )
            return tempSonarFilter
          })
        }
      }

      setActiveFilters(prev =>
        prev.filter(filter => filter.id !== activeFilter.id)
      )
    },
    [setActiveFilters, setSonarFilter]
  )

  /** Handler for the Clear All Filters button click */
  const filterPanelChipsButtonClickHandler = useCallback(() => {
    const isAlarmsTabActive = tabValue === 1
    const defaultRange = isAlarmsTabActive ? 'today' : 'all'
    const { currentDateUTC, cutoffDate } = getUTCDateBounds()
    const defaultStartDate = isAlarmsTabActive
      ? formatDateObjectToString(currentDateUTC, 'YYYY-MM-DD', 'true')
      : ''
    const defaultEndDate = isAlarmsTabActive
      ? formatDateObjectToString(cutoffDate, 'YYYY-MM-DD', 'true')
      : ''

    setRangeSelected(defaultRange)
    setStartDateSelected(defaultStartDate)
    setEndDateSelected(defaultEndDate)

    setSonarFilter(prevSonarFilter => {
      const tempSonarFilter: ISonarFilter = {
        ...prevSonarFilter,
        technology: ['Plc'],
        divisionId: [],
        plantId: [],
        systemId: [],
        region: [],
        country: [],
        state: [],
        city: [],
        lastSeenDateRange: [defaultRange],
        lastSeenStartDate: [defaultStartDate ?? ''],
        lastSeenEndDate: [defaultEndDate ?? ''],
        shouldShowSnoozed: ['false'],
        shouldShowUnsnoozed: ['true'],
        timeDown: [],
        alarmSeverity: [],
        alarmType: [],
        snoozeStatus: ['shouldShowUnsnoozed'],
      }

      return tempSonarFilter
    })
    setActiveFilters([])
  }, [tabValue, setActiveFilters, setSonarFilter])

  /** Handler for the Technology Types chips selection in the Filter Panel */
  const technologyTypesChipClickHandler = useCallback(
    (_: Filter, option: FilterOption) => {
      setSonarFilter(prevSonarFilter => {
        const tempSonarFilter = {
          ...prevSonarFilter,
        }

        // If the option.id is already in the tempSonarFilter.technology, remove it, otherwise, add it
        if (tempSonarFilter.technology.includes(option.id)) {
          tempSonarFilter.technology = tempSonarFilter.technology.filter(
            tech => tech !== option.id
          )
        } else {
          tempSonarFilter.technology = [
            ...tempSonarFilter.technology,
            option.id,
          ]
        }

        return tempSonarFilter
      })
    },
    [setSonarFilter]
  )

  const snoozeStatusChipClickHandler = useCallback(
    (_: Filter, option: FilterOption) => {
      setSonarFilter(prevSonarFilter => {
        const tempSonarFilter = {
          ...prevSonarFilter,
        }

        // If the option.id is already selected, remove it, otherwise, add it
        if (tempSonarFilter.snoozeStatus.includes(option.id)) {
          tempSonarFilter.snoozeStatus = tempSonarFilter.snoozeStatus.filter(
            (status: string) => status !== option.id
          )
        } else {
          tempSonarFilter.snoozeStatus = [
            ...tempSonarFilter.snoozeStatus,
            option.id,
          ]
        }
        // Update shouldShowSnoozed and shouldShowUnsnoozed at the same time as snoozeStatus to avoid multiple table requests being sent
        tempSonarFilter.shouldShowSnoozed = [
          tempSonarFilter.snoozeStatus?.includes('shouldShowSnoozed')
            ? 'true'
            : 'false',
        ]
        tempSonarFilter.shouldShowUnsnoozed = [
          tempSonarFilter.snoozeStatus?.includes('shouldShowUnsnoozed')
            ? 'true'
            : 'false',
        ]
        return tempSonarFilter
      })
    },
    [setSonarFilter]
  )

  const filterChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => {
      return (_: Filter, property: { id: number | string } | null) => {
        setSonarFilter((prevSettings: ISonarFilter) => {
          const newSettings = {
            ...prevSettings,
            [propertyName]: property ? [property.id] : [],
          }

          return newSettings
        })
      }
    },
    [setSonarFilter]
  )

  const dateRangeChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => {
      return (_: Filter, property: { id: number | string } | null) => {
        setSonarFilter((prevSettings: ISonarFilter) => {
          let dateRangeSettings = {}
          if (!property) {
            dateRangeSettings = {
              lastSeenStartDate: [],
              lastSeenEndDate: [],
            }
          }
          const newSettings = {
            ...prevSettings,
            ...dateRangeSettings,
            [propertyName]: property ? [property.id] : [],
          }
          return newSettings
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSonarFilter]
  )

  const timeDownChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => dateRangeChangeHandler(propertyName),
    [dateRangeChangeHandler]
  )

  const lastSeenChangeHandler = useCallback(
    (propertyName: keyof ISonarFilter) => dateRangeChangeHandler(propertyName),
    [dateRangeChangeHandler]
  )

  const timezoneChangeHandler = useCallback(() => {
    setSonarFrontendFilter(prevSonarFrontendFilter => {
      return SonarViewHelper.timezoneChangeHandlerHelper(
        prevSonarFrontendFilter
      )
    })
  }, [setSonarFrontendFilter])

  const isMenuOpenChangeHandler = useCallback(
    (filterName: string, status: string) => {
      setSonarFrontendFilter(prevSonarFrontendFilter => {
        return SonarViewHelper.isMenuOpenHandlerHelper(
          prevSonarFrontendFilter,
          filterName,
          status
        )
      })
    },
    [setSonarFrontendFilter]
  )

  const processTechnologyFilters = useCallback(() => {
    const technologyFilters = SonarViewHelper.processSelectedTechnologyFilter(
      sonarFilter.technology
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all technology items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'technology'
      )

      // Merge the arrays
      return [...technologyFilters, ...tempActiveFilters]
    })
  }, [sonarFilter.technology])

  const processCustomerFilters = useCallback(() => {
    if (!customerFilterOptions) return
    const customerFilters = SonarViewHelper.processSelectedCustomerFilter(
      sonarFilter,
      customerFilterOptions
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all customer items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'customer'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...customerFilters]
    })
  }, [sonarFilter, customerFilterOptions])

  const processDateRangeFilters = useCallback(() => {
    const dateRangeFilters = SonarViewHelper.processSelectedDateRangeFilter(
      tabValue ?? 0,
      sonarFilter,
      rangeSelected,
      startDateSelected,
      endDateSelected
    )
    setClearRangeSelected(dateRangeFilters.length === 0)

    setActiveFilters(prevActiveFilters => {
      // Filter out all date range items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'dateRange'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...dateRangeFilters]
    })
  }, [rangeSelected, sonarFilter, startDateSelected, endDateSelected, tabValue])

  const processDateRangeForAtomFilter = useCallback(() => {
    setSonarFilter(prevSonarFilter => {
      const tempSonarFilter = { ...prevSonarFilter }
      if (rangeSelected) {
        tempSonarFilter.lastSeenDateRange = rangeSelected ? [rangeSelected] : []
        tempSonarFilter.lastSeenStartDate = startDateSelected
          ? [startDateSelected]
          : []
        tempSonarFilter.lastSeenEndDate = endDateSelected
          ? [endDateSelected]
          : []

        // Store the date ranges in session
        sessionStorage.setItem('sonar-lastSeenDateRange', rangeSelected ?? '')
        sessionStorage.setItem(
          'sonar-lastSeenStartDate',
          startDateSelected ?? ''
        )
        sessionStorage.setItem('sonar-lastSeenEndDate', endDateSelected ?? '')
      } else {
        tempSonarFilter.lastSeenDateRange = []
        tempSonarFilter.lastSeenStartDate = []
        tempSonarFilter.lastSeenEndDate = []

        sessionStorage.removeItem('sonar-lastSeenDateRange')
        sessionStorage.removeItem('sonar-lastSeenStartDate')
        sessionStorage.removeItem('sonar-lastSeenEndDate')
      }
      return tempSonarFilter
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endDateSelected,
    rangeSelected,
    setSonarFilter,
    startDateSelected,
    tabValue,
  ])

  const processTimeDownFilters = useCallback(() => {
    const timeDownFilters = SonarViewHelper.processSelectedTimeDownFilter(
      sonarFilter.timeDown?.[0] ?? '',
      tabValue ?? 0
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all time down items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'timeDown'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...timeDownFilters]
    })
  }, [sonarFilter.timeDown, tabValue])

  const processTimeDownForAtomFilter = useCallback(() => {
    setSonarFilter(prevSonarFilter => {
      const tempSonarFilter = { ...prevSonarFilter }
      // When using the Time Down filter, ORCA send Kelowna a start date and end date with no time.
      // Kelowna decides the range is from start date @ midnight (00:00) to end date midnight @ (00:00)
      // When the Time Down selection is < 10 days (nine days ago to today), ORCA wants the current day included.
      // Therefore ORCA gets the current date in UTC and adds 1.
      // ORCA manipulates the date in UTC instead of local time to avoid any weirdness with Kelowna since
      // they handle dates in UTC
      const cutoff = new Date()
      cutoff.setUTCDate(cutoff.getUTCDate() + 1)
      cutoff.setUTCHours(0, 0, 0, 0)
      if (sonarFilter?.timeDown?.length) {
        const { startDate, endDate } = SonarViewHelper.getTimeDownDateRanges(
          sonarFilter?.timeDown,
          cutoff
        )
        tempSonarFilter.lastSeenStartDate = startDate ? [startDate] : []
        tempSonarFilter.lastSeenEndDate = endDate ? [endDate] : []
      } else {
        tempSonarFilter.lastSeenStartDate = startDateSelected
          ? [startDateSelected]
          : []
        tempSonarFilter.lastSeenEndDate = endDateSelected
          ? [endDateSelected]
          : []
      }
      return tempSonarFilter
    })
  }, [
    setSonarFilter,
    sonarFilter?.timeDown,
    startDateSelected,
    endDateSelected,
  ])

  const processSnoozeStatusFilters = useCallback(() => {
    const alarmStatusFilters = SonarViewHelper.processSelectedSnoozeStatusFilter(
      sonarFilter.snoozeStatus || []
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all snoozed alarms items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'snoozeStatus'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...alarmStatusFilters]
    })
  }, [sonarFilter.snoozeStatus])

  const processAlarmSeverityFilters = useCallback(() => {
    const alarmSeverityFilters = SonarViewHelper.processSelectedAlarmSeverityFilter(
      sonarFilter.alarmSeverity || [],
      tabValue ?? 0
    )

    setActiveFilters(prevActiveFilters => {
      // Filter out all alarms severity items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'alarmSeverity'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...alarmSeverityFilters]
    })
  }, [sonarFilter.alarmSeverity, tabValue])

  const processAlarmTypeFilters = useCallback(() => {
    if (!alarmTypeOptions?.length) return

    const alarmTypeFilters =
      SonarViewHelper.processSelectedAlarmTypeFilter(
        sonarFilter.alarmType || [],
        tabValue ?? 0,
        alarmTypeOptions
      ) || []

    setActiveFilters(prevActiveFilters => {
      // Filter out all alarms severity items
      const tempActiveFilters = prevActiveFilters.filter(
        prevFilter => prevFilter.property !== 'alarmType'
      )

      // Merge the arrays
      return [...tempActiveFilters, ...alarmTypeFilters]
    })
  }, [sonarFilter.alarmType, tabValue, alarmTypeOptions])

  // Removes filters that are irrelevant to the Overview tab
  const processOverviewTabFilters = useCallback(() => {
    const isOverviewTabActive = tabValue === 0
    if (isOverviewTabActive) {
      setActiveFilters(prevActiveFilters => {
        const tempActiveFilters = prevActiveFilters.filter(
          prevFilter =>
            prevFilter.property !== 'alarmSeverity' &&
            prevFilter.property !== 'dateRange' &&
            prevFilter.property !== 'snoozeStatus' &&
            prevFilter.property !== 'alarmType' &&
            prevFilter.property !== 'timeDown'
        )

        return [...tempActiveFilters]
      })
    }
  }, [tabValue])

  const getFilterValue = (filter: ISonarFilter) => {
    const options = filter.options
    const filterValue = sonarFilter[filter.property]

    if (options?.length && filterValue?.length) {
      const matchedOption = options.find(
        (option: ISonarFilterOption) =>
          option?.id.toString() === filterValue[0].toString()
      )
      return matchedOption || null
    }

    if (filter.property === 'isUTC') {
      if (options?.length && sonarFrontendFilter.isUTC) {
        const matchedOption = options.find(
          (option: ISonarFilterOption) =>
            option?.id === sonarFrontendFilter.isUTC?.[0]
        )
        return matchedOption.id
      }
    }
    return null
  }

  const getCustomerFilterOptions = useCallback(async () => {
    const tempSonarFilter = { ...sonarFilter }
    const queryParams = SonarViewHelper.generateQueryParams(tempSonarFilter)
    const tempCustomerFilterOptions = await SonarViewHelper.getSonarCustomerFilterOptions(
      queryParams,
      setIsLoading
    )

    setCustomerFilterOptions(tempCustomerFilterOptions)
  }, [sonarFilter])

  const getAlarmTypeFilterOptions = useCallback(async () => {
    const tempSonarFilter = { ...sonarFilter }
    const queryParams = SonarViewHelper.generateQueryParams(tempSonarFilter)
    const allowedKeys = ['technology', 'alarmSeverity']
    const updatedParams = SonarViewHelper.filterQueryParams(
      queryParams,
      allowedKeys
    )

    const alarmTypeFilterOptions = await SonarViewHelper.getSonarAlarmTypeFilterOptions(
      updatedParams,
      setIsLoading
    )

    setAlarmTypeOptions(alarmTypeFilterOptions)

    // This method checks if the selected alarm type is in the list of existing alarm type filter options.
    // If it is not on the list, it is removed from the filter as it is deemed an invalid option selected
    // based on the severity and technology filters selected.
    if (alarmTypeFilterOptions.length > 0) {
      const allowedAlarmTypes = alarmTypeFilterOptions?.map(option =>
        //@ts-ignore
        option.id.toString()
      )

      const validAlarmTypes = sonarFilter.alarmType?.filter(alarmType =>
        allowedAlarmTypes.includes(alarmType)
      )

      // Update the sonarFilter state with the filtered array
      setSonarFilter(prevState => ({
        ...prevState,
        alarmType: validAlarmTypes,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sonarFilter.technology, sonarFilter.alarmSeverity])

  const isChipActive = (option: FilterOption) => {
    const technology = sonarFilter?.technology || []
    const snoozeStatus = sonarFilter?.snoozeStatus || []
    if (technology.includes(option.id) || snoozeStatus.includes(option.id))
      return true

    return false
  }

  /** Defines the filter helper variables */
  const filterPanelHelperVariables = {
    isChipActive: isChipActive,
  }

  //Effects
  useEffect(() => {
    if (!isFirstRender) {
      updateUrlParams()
      return
    }

    const params = new URLSearchParams(search)
    const lastSeenDateRange = params.get('lastSeenDateRange')
    const newSettings = getNewSettings(sonarFilter, params)

    // We need to maintain URL Param dates if the type is a custom date range
    const isCustomRange = lastSeenDateRange === SonarDateRange.CustomRange
    if (isCustomRange) {
      const startDate = params.get('lastSeenStartDate')
      const endDate = params.get('lastSeenEndDate')

      setRangeSelected(lastSeenDateRange)
      if (startDate && endDate) {
        setStartDateSelected(startDate)
        setEndDateSelected(endDate)
      }
      setSonarFilter(newSettings as ISonarFilter)
    } else {
      // Use adjusted dates for other date range types
      const adjustedDates = sonarDateRangeSelectHelper.adjustDateRangeFromUrl(
        lastSeenDateRange
      )
      if (adjustedDates.lastSeenDateRange) {
        setRangeSelected(adjustedDates.lastSeenDateRange)
        if (adjustedDates.lastSeenStartDate && adjustedDates.lastSeenEndDate) {
          setStartDateSelected(adjustedDates.lastSeenStartDate)
          setEndDateSelected(adjustedDates.lastSeenEndDate)
        }
      }
      setSonarFilter(
        sonarDateRangeSelectHelper.updateSonarFilterWithDates(
          newSettings as ISonarFilter,
          adjustedDates
        )
      )
    }

    setIsFirstRender(false)
  }, [isFirstRender, sonarFilter, search, setSonarFilter, updateUrlParams])

  useEffect(() => {
    processCustomerFilters()
    processTechnologyFilters()
    processSnoozeStatusFilters()
    processAlarmSeverityFilters()
    processAlarmTypeFilters()
    processDateRangeFilters()
    processTechnologyFilters()
    processTimeDownFilters()
    processOverviewTabFilters()
  }, [
    tabValue,
    processCustomerFilters,
    processDateRangeFilters,
    processSnoozeStatusFilters,
    processAlarmSeverityFilters,
    processAlarmTypeFilters,
    processTechnologyFilters,
    processTimeDownFilters,
    processOverviewTabFilters,
  ])

  useEffect(() => {
    setFilterCount(activeFilters.length)
  }, [activeFilters])

  useEffect(() => {
    setExpandFilters(prev => ({
      ...prev,
      customer: tabValue === 0 || tabValue === 1, // expand customer filter on overview and alarms tabs
    }))
  }, [tabValue])

  useEffect(() => {
    getCustomerFilterOptions()
  }, [getCustomerFilterOptions])

  useEffect(() => {
    getAlarmTypeFilterOptions()
  }, [getAlarmTypeFilterOptions])

  useEffect(() => {
    processDateRangeForAtomFilter()
  }, [processDateRangeForAtomFilter])

  useEffect(() => {
    processTimeDownForAtomFilter()
  }, [processTimeDownForAtomFilter])

  useEffect(() => {
    // Clear sessionStorage when the component unmounts
    return () => {
      sessionStorage.removeItem('sonar-lastSeenDateRange')
      sessionStorage.removeItem('sonar-lastSeenStartDate')
      sessionStorage.removeItem('sonar-lastSeenEndDate')
    }
  }, [])

  // Reset technology to default when no technology is selected
  useEffect(() => {
    // Set a timeout to reset to default after 1 second
    if (!sonarFilter.technology?.length) {
      const timeoutId = setTimeout(() => {
        setSonarFilter(prevSettings => ({
          ...prevSettings,
          technology: ['Plc'],
        }))
      }, 1000)

      // Cleanup function
      return () => clearTimeout(timeoutId)
    }
  }, [sonarFilter.technology, setSonarFilter])

  // Reset snoozeStatus to default when no snooze status is selected
  useEffect(() => {
    // Set a timeout to reset to default after 1 second
    if (!sonarFilter.snoozeStatus?.length) {
      const timeoutId = setTimeout(() => {
        setSonarFilter(prevSettings => ({
          ...prevSettings,
          snoozeStatus: ['shouldShowUnsnoozed'],
          shouldShowSnoozed: ['false'],
          shouldShowUnsnoozed: ['true'],
        }))
      }, 1000)

      // Cleanup function
      return () => clearTimeout(timeoutId)
    }
  }, [sonarFilter.snoozeStatus, setSonarFilter])

  //Clear time down filters when switching to Overview tab
  useEffect(() => {
    const isDownSystemsActive = tabValue === 2
    if (!isDownSystemsActive && sonarFilter.timeDown?.length) {
      setSonarFilter(prevSettings => ({
        ...prevSettings,
        timeDown: [],
        lastSeenStartDate: [],
        lastSeenEndDate: [],
      }))
    }
  }, [sonarFilter.timeDown, tabValue, setSonarFilter])

  // Change 'Today' or 'Yesterday' date range to 'All' when switching to Down Systems tab
  useEffect(() => {
    if (isLoading || tabValue !== 2) return // Skip when loading or not in DS tab

    if (
      sonarFilter.lastSeenDateRange?.[0] === 'today' ||
      sonarFilter.lastSeenDateRange?.[0] === 'yesterday'
    ) {
      setSonarFilter(prevSettings => ({
        ...prevSettings,
        lastSeenDateRange: ['all'],
        lastSeenStartDate: [],
        lastSeenEndDate: [],
      }))
      setRangeSelected('all')
      setStartDateSelected(null)
      setEndDateSelected(null)
    }
  }, [isLoading, sonarFilter.lastSeenDateRange, tabValue, setSonarFilter])

  // Change date range from 'All' to 'Today' in Alarms tab when isTabSwitching is true
  useEffect(() => {
    if (isLoading || tabValue !== 1) return // Skip when loading or not in Alarms tab

    const { currentDateUTC, cutoffDate } = getUTCDateBounds()
    const startDate = formatDateObjectToString(
      currentDateUTC,
      'YYYY-MM-DD',
      'true'
    )
    const endDate = formatDateObjectToString(cutoffDate, 'YYYY-MM-DD', 'true')

    if (
      sonarFrontendFilter.isTabSwitching?.[0] === 'true' &&
      sonarFilter.lastSeenDateRange?.[0] === 'all'
    ) {
      setSonarFilter(prevSettings => ({
        ...prevSettings,
        lastSeenDateRange: ['today'],
        lastSeenStartDate: [startDate as string],
        lastSeenEndDate: [endDate as string],
      }))
      setRangeSelected('today')
      setStartDateSelected(startDate)
      setEndDateSelected(endDate)
    }
  }, [
    isLoading,
    sonarFilter.lastSeenDateRange,
    tabValue,
    setSonarFilter,
    sonarFrontendFilter.isTabSwitching,
  ])

  // Reset date range to default when no date range is selected
  useEffect(() => {
    if (isLoading || tabValue === 0) return // Skip when loading or at the overview tab

    const isAlarmsTabActive = tabValue === 1
    const defaultRange = isAlarmsTabActive ? 'today' : 'all'
    const { currentDateUTC, cutoffDate } = getUTCDateBounds()
    const defaultStartDate = isAlarmsTabActive
      ? formatDateObjectToString(currentDateUTC, 'YYYY-MM-DD', 'true')
      : ''
    const defaultEndDate = isAlarmsTabActive
      ? formatDateObjectToString(cutoffDate, 'YYYY-MM-DD', 'true')
      : ''

    const timeoutDuration =
      sonarFrontendFilter.isTabSwitching?.[0] === 'true' ? 0 : 2000 // Instant reset when tab switching, or wait 2s for user interaction

    if (
      !sonarFilter.lastSeenDateRange?.length &&
      !sonarFilter.timeDown?.length &&
      sonarFrontendFilter.isMenuOpen?.[0] === 'false'
    ) {
      const timeoutId = setTimeout(() => {
        setRangeSelected(defaultRange)
        setStartDateSelected(defaultStartDate)
        setEndDateSelected(defaultEndDate)
        setSonarFilter((prevSettings: ISonarFilter) => ({
          ...prevSettings,
          lastSeenDateRange: [defaultRange],
          lastSeenStartDate: [defaultStartDate ?? ''],
          lastSeenEndDate: [defaultEndDate ?? ''],
        }))
      }, timeoutDuration)

      // Cleanup function
      return () => clearTimeout(timeoutId)
    }
  }, [
    isLoading,
    sonarFrontendFilter.isMenuOpen,
    sonarFrontendFilter.isTabSwitching,
    sonarFilter.lastSeenDateRange,
    sonarFilter.timeDown,
    tabValue,
    setSonarFilter,
  ])

  /* Defines the filter items that will appear on the filter panel.
   *
   */
  const allFilterPanelItems = [
    {
      category: 'technology',
      filters: [
        {
          componentType: FilterComponentTypes.ChipsMulti,
          disabled: () => false,
          heading: '',
          name: '',
          onClickHandler: technologyTypesChipClickHandler,
          options: technologyTypes,
          property: 'technologyTypes',
        },
      ],
      name: 'TECHNOLOGY',
    },
    {
      category: 'customer',
      filters: [
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          heading: '',
          name: 'Producer',
          onChangeHandler: filterChangeHandler('divisionId'),
          options: customerFilterOptions.divisionId,
          property: 'divisionId',
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          heading: '',
          name: 'Plant',
          onChangeHandler: filterChangeHandler('plantId'),
          options: customerFilterOptions.plantId,
          property: 'plantId',
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          heading: '',
          name: 'System ID',
          onChangeHandler: filterChangeHandler('systemId'),
          options: customerFilterOptions.systemId,
          property: 'systemId',
        },
        {
          componentType: FilterComponentTypes.Accordion,
          property: 'location',
          name: '',
          nestedFilter: [
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: 'Location',
              name: 'Region',
              onChangeHandler: filterChangeHandler('region'),
              options: customerFilterOptions.region,
              property: 'region',
            },
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: '',
              name: 'Country',
              onChangeHandler: filterChangeHandler('country'),
              options: customerFilterOptions.country,
              property: 'country',
            },
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: '',
              name: 'State',
              onChangeHandler: filterChangeHandler('state'),
              options: customerFilterOptions.state,
              property: 'state',
            },
            {
              componentType: FilterComponentTypes.AutocompleteSingle,
              disabled: () => false,
              heading: '',
              name: 'City',
              onChangeHandler: filterChangeHandler('city'),
              options: customerFilterOptions.city,
              property: 'city',
            },
          ],
        },
      ],
      name: 'CUSTOMER',
    },
    {
      category: 'downSystems',
      name: 'DOWN SYSTEMS',
      filters: [
        {
          property: 'snoozeStatus',
          name: 'snoozeStatus',
          componentType: FilterComponentTypes.ChipsMulti,
          disabled: () => false,
          options: snoozeStatusOptions,
          onClickHandler: snoozeStatusChipClickHandler,
          bottomSpacing: '12px',
        },
        {
          componentType: FilterComponentTypes.DateRangeSelect,
          disabled: () => sonarFilter?.timeDown?.length,
          heading: '',
          name: 'Last Seen Date Range',
          onChangeHandler: lastSeenChangeHandler('lastSeen'),
          property: 'lastSeen',
          options: null,
          menuStatusChangeHandler: isMenuOpenChangeHandler,
          tabStatusChangeHandler: SonarViewHelper.tabSwitchingHandler,
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => sonarFilter?.lastSeenDateRange?.length,
          heading: '',
          name: 'Time Down',
          onChangeHandler: timeDownChangeHandler('timeDown'),
          options: timeDownOptions,
          property: 'timeDown',
          menuStatusChangeHandler: isMenuOpenChangeHandler,
        },
      ],
    },
    {
      category: 'alarms',
      name: 'ALARMS',
      filters: [
        {
          property: 'snoozeStatus',
          name: 'snoozeStatus',
          componentType: FilterComponentTypes.ChipsMulti,
          disabled: () => false,
          options: snoozeStatusOptions,
          onClickHandler: snoozeStatusChipClickHandler,
          bottomSpacing: '12px',
        },
        {
          componentType: FilterComponentTypes.DateRangeSelect,
          disabled: () => false,
          name: 'Date Range',
          onChangeHandler: lastSeenChangeHandler('lastSeen'),
          property: 'alarmDateRange',
          options: null,
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          name: 'Severity',
          onChangeHandler: filterChangeHandler('alarmSeverity'),
          options: alarmSeverityOptions,
          property: 'alarmSeverity',
        },
        {
          componentType: FilterComponentTypes.AutocompleteSingle,
          disabled: () => false,
          name: 'Alarm Type',
          onChangeHandler: filterChangeHandler('alarmType'),
          options: alarmTypeOptions,
          property: 'alarmType',
        },
      ],
    },
    {
      category: 'timezone',
      name: 'TIME ZONE',
      filters: [
        {
          property: 'isUTC',
          name: 'Timezone',
          componentType: FilterComponentTypes.RadioSingleSelect,
          onChangeHandler: timezoneChangeHandler,
          options: [
            {
              id: 'false',
              name: `Local (UTC${getLocalTimezoneOffsetFromUTC()})`,
            },
            {
              id: 'true',
              name: 'UTC (UTC+00:00)',
            },
          ],
          isHorizontal: false,
          color: 'secondary',
        },
      ],
    },
  ]

  const filterToExclude = () => {
    switch (tabValue) {
      case 0:
        return ['ALARMS', 'DOWN SYSTEMS'] // Overview is active, exclude Down Systems and Alarms filter items
      case 1:
        return ['OVERVIEW', 'DOWN SYSTEMS'] // Alarms is active, exclude Overview and Down Systems filter items
      case 2:
        return ['OVERVIEW', 'ALARMS'] // Down Systems is active, exclude Overview and Alarms filter items
      default:
        return []
    }
  }

  const getFilterPanelItems = () => {
    const excludedFilters = filterToExclude()
    return allFilterPanelItems.filter(
      item => !excludedFilters.includes(item.name)
    )
  }

  const [filterPanelItems, setFilterPanelItems] = useState(getFilterPanelItems)

  // Update filter panel items when tab value changes
  useEffect(() => {
    const filterPanelItems = getFilterPanelItems()
    setFilterPanelItems(filterPanelItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sonarFilter, tabValue, customerFilterOptions, alarmTypeOptions])

  return {
    activeFilters,
    alarmTypeOptions,
    addDataSettings,
    clearRangeSelected,
    customerFilterOptions,
    endDateSelected,
    expandFilters,
    filterButtonClickHandler,
    filterChangeHandler, // Returned for testing purposes
    filterCount,
    filterPanelChipsButtonClickHandler,
    filterPanelChipsChipClickHandler,
    filterPanelHelperVariables,
    filterPanelItems,
    getFilterValue,
    isFilterOpen,
    isLoading,
    isChipActive, // Returned for testing purposes
    isMenuOpenChangeHandler, // Returned for testing purposes
    rangeSelected,
    setAddDataSettings,
    setEndDateSelected,
    setExpandFilters,
    setIsFilterOpen,
    setRangeSelected,
    setSonarFilter,
    setSonarFrontendFilter,
    setStartDateSelected,
    setIsLoading,
    dateRangeChangeHandler, // Returned for testing purposes
    timeDownChangeHandler, // Returned for testing purposes
    lastSeenChangeHandler, // Returned for testing purposes
    snoozeStatusChipClickHandler, // Returned for testing purposes
    sonarFilter,
    startDateSelected,
    tabValue, // Returned for testing purposes
    technologyTypesChipClickHandler, // Returned for testing purposes
  }
}

const hooks = {
  useSonarView,
}

export default hooks
