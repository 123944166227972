import React from 'react'
import { ISonarOverviewSystemSummary } from '../../../../Common/Logic/Types'
import { systemSummaryHelper } from './SystemSummaryHelper'
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { makeStyles, Paper } from '@material-ui/core'
import theme from '../../../../theme/muiTheme'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { baseColors } from '../../../../theme/colors'
import { useReflowGraphDueToFilterPanel } from '../../../../Common/Hooks/hooks'

export interface ISystemSummary {
  systemSummaryData: ISonarOverviewSystemSummary | null
  isFilterOpen: boolean
}

const useStyles = makeStyles(() => ({
  //@ts-ignore
  ...theme.customClasses.sonarOverviewVisuals,
  highchartsContainer: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}))
declare module 'highcharts' {
  interface Point {
    count: number
    tooltipPercent: number
  }
}
const SystemSummary = (props: ISystemSummary) => {
  const { systemSummaryData, isFilterOpen } = props
  const classes: ClassNameMap = useStyles()
  const formattedSystemSummaryData = systemSummaryHelper.formatSystemSummaryData(
    systemSummaryData
  )

  const { chartComponentRef } = useReflowGraphDueToFilterPanel({ isFilterOpen })

  const options: Options = {
    chart: {
      type: 'bar',
      height: 124,
      spacingTop: 0,
      spacingBottom: 5,
      marginTop: 0,
      marginBottom: 0,
    },
    title: {
      text: 'System Summary',
      style: { margin: '0', fontSize: '18px', fontFamily: 'Inter' },
      align: 'left',
      useHTML: true,
    },
    xAxis: {
      visible: false,
      categories: ['Category'],
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: 'Percentage',
      },
      visible: false,
    },
    legend: {
      reversed: true,
      align: 'left',
      padding: 0,
      itemStyle: {
        color: baseColors.text.secondary,
        fontWeight: '500px',
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
        events: {
          legendItemClick: function(e: any) {
            e.preventDefault()
          },
        },
        // @ts-ignore
        borderRadius: 4,
        stacking: 'normal',
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      borderRadius: 12,
      formatter: function(this: Highcharts.TooltipFormatterContextObject) {
        // @SONAR_STOP@
        // Skip sonar scanning as this is HighCharts suggested code functionality
        const point = this.point
        // @SONAR_START@

        const dateString = systemSummaryHelper.getDateString()
        const tooltip = systemSummaryHelper.getTooltip(
          point.name,
          point.count,
          point.tooltipPercent,
          dateString
        )
        return tooltip
      },
      positioner: function(labelWidth, _labelHeight, point) {
        // @SONAR_STOP@
        // Skip sonar scanning as this is HighCharts suggested code functionality
        const chart = this.chart
        // @SONAR_START@
        return systemSummaryHelper.getTooltipPosition(chart, point, labelWidth)
      },
    },
    // @ts-ignore
    series: systemSummaryHelper.getSystemSummarySeries(
      formattedSystemSummaryData
    ),
  }

  return (
    <Paper className={classes.paperContainer}>
      <div className={classes.highchartsContainer}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      </div>
    </Paper>
  )
}

export default SystemSummary
