import {
  AddDataSettings,
  IAtomCurrentCustomer,
  MixGroupConditions,
} from '../Logic/Types'

export const handleChipLabel = (
  settingName: string,
  settingValue: string,
  currentCustomer: IAtomCurrentCustomer
) => {
  const labelValue = settingValue ?? ''
  const customer = currentCustomer?.division?.name ?? ''
  const plant = currentCustomer?.plant?.name ?? ''

  let finalLabel = ''
  switch (settingName) {
    case 'divisionId':
      finalLabel = customer
      break
    case 'isCO2Design':
      finalLabel = `Condition: ${
        settingValue === 'true'
          ? MixGroupConditions.CO2
          : MixGroupConditions.NOCO2
      }`
      break
    case 'mixCode':
      finalLabel = settingValue && `Mix Code: ${settingValue}`
      break
    case 'plantId':
      finalLabel = plant
      break
    case 'minNumSamples':
      finalLabel = `Min. Samples: ${labelValue}`
      break
    case 'maxNumSamples':
      finalLabel = `Max. Samples: ${labelValue}`
      break
    case 'showFullLibrary':
      finalLabel = settingValue === 'true' ? 'Show Full Library' : ''
      break
    case 'includeOnlyMissingDesignStrength':
      finalLabel =
        settingValue === 'true' ? 'Missing Info: Design Strength' : ''
      break
    case 'includeOnlyMissingMixAssociation':
      finalLabel =
        settingValue === 'true' ? 'Missing Info: Mix Association' : ''
      break
  }

  return {
    property: settingName,
    label: finalLabel,
  }
}

export const mapSettingsToParams = (
  addDataSettings: AddDataSettings,
  paramName: string
) => {
  return addDataSettings[paramName]?.length
    ? { [paramName]: addDataSettings[paramName][0] }
    : {}
}
