import React, { Dispatch, SetStateAction } from 'react'
import {
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import hook, { IUseDownSystemsTableProps } from './useDownSystemsTable'
import {
  ISonarDownSystem,
  TableCellAlignment,
  TSortOrder,
} from '../../../../Common/Logic/Types'
import downSystemsTableHelper from './DownSystemsTableHelper'
import { formatDateObjectToString } from '../../../../Common/Helpers/GeneralHelpers'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import SimpleTablePagination from '../../../../Common/Components/SimpleTable/SimpleTablePagination'
import SnoozeDownSystemsModal from '../SnoozeDownSystemsModal/SnoozeDownSystemsModal'
import { baseColors } from '../../../../theme/colors'
import Snooze from '@material-ui/icons/Snooze'
import { hardwareCanWrite } from '../../../Logic/HardwareLogic'
import typography from '../../../../theme/typography'
import TableSortLabelComponent from '../../../../Common/Components/TableSortLabel/TableSortLabelComponent'
import { Alert } from '@material-ui/lab'

export interface IDownSystemsTableProps extends IUseDownSystemsTableProps {
  count: number
  downSystemsData: ISonarDownSystem[]
  onColumnHeaderClick: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    column: string
  ) => void
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: TSortOrder
  orderBy: string
  page: number
  rowsPerPage: number
  setSnoozeSavedFlag: Dispatch<SetStateAction<boolean>>
}

type Classes = ReturnType<typeof useStyles>

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  downSystemLessThan10Days: {
    fontWeight: typography.fontWeight.bold,
    backgroundColor: 'rgba(140, 161, 185, 1)',
    border: `2px solid rgba(140, 161, 185, 0.26)`,
    color: `#FFFFFF`,
    outline: `2px solid rgba(225, 231, 237, 1)`,
    textShadow: `0.4px 0.4px 0.8px rgba(0, 0, 0, 0.80);`,
  },
  downSystemFrom10to30Days: {
    fontWeight: typography.fontWeight.bold,
    backgroundColor: 'rgba(94, 123, 157, 1)',
    border: `2px solid rgba(94, 123, 157, 0.26)`,
    color: `#FFFFFF`,
    outline: `2px solid rgba(213, 221, 230, 1)`,
  },
  downSystemFrom30to180Days: {
    fontWeight: typography.fontWeight.bold,
    backgroundColor: 'rgba(26, 67, 115, 1)',
    border: `2px solid rgba(26, 67, 115, 0.26)`,
    color: `#FFFFFF`,
    outline: `2px solid rgba(196, 206, 219, 1)`,
  },
  downSystemAbove180Days: {
    fontWeight: typography.fontWeight.bold,
    backgroundColor: 'rgba(13, 33, 57, 1)',
    border: `2px solid rgba(13, 33, 57, 0.26)`,
    color: `#FFFFFF`,
    outline: `2px solid rgba(192, 198, 204, 1)`,
  },
  headerCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  rowCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  snoozeRowCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: baseColors.table.shadedBackground,
  },
  snoozeIcon: {
    color: baseColors.text.secondary,
  },
  tooltip: {
    fontSize: '10px',
    lineHeight: '14px',
    letterSpacing: '0.2px',
  },
}))

export interface IDownSystemsTableProps extends IUseDownSystemsTableProps {}

const DownSystemsTable = ({
  count,
  downSystemsData,
  onColumnHeaderClick,
  onChangePage,
  onChangeRowsPerPage,
  order,
  orderBy,
  page,
  rowsPerPage,
  setSnoozeSavedFlag,
}: IDownSystemsTableProps) => {
  const classes = useStyles()

  const {
    JWT,
    showSnoozeMenu,
    anchorEl,
    handleActionButtonClick,
    handleSnoozeMenuClose,
    showSnoozeModal,
    handleSnoozeModalOpen,
    setShowSnoozeModal,
    clickedDownSystem,
    setClickedDownSystem,
    showSnoozeSuccessModal,
    setShowSnoozeSuccessModal,
    sonarFrontendFilters,
    downSystemsTableHeadCells,
  } = hook.useDownSystemsTable()

  const renderTableHeader = () => {
    return (
      <TableHead>
        <TableRow>
          {downSystemsTableHeadCells.map(cell => (
            <TableCell
              key={cell.id}
              align={cell.align as TableCellAlignment}
              title={cell.tooltip}
              width={cell.width}
              className={classes.headerCell}
            >
              {cell.sortable ? (
                <TableSortLabelComponent
                  cell={cell}
                  order={order}
                  orderBy={orderBy}
                  onColumnHeaderClick={onColumnHeaderClick}
                  getOrderDirection={downSystemsTableHelper.getOrderDirection}
                />
              ) : (
                cell.name
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }

  const renderActionButton = (downSystem: ISonarDownSystem) => {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={event => handleActionButtonClick(event, downSystem)}
          data-testid="action-button"
        >
          <MoreVertIcon />
        </IconButton>
        {showSnoozeMenu &&
          renderMenu(
            downSystem.snoozeId,
            downSystem.remoteUnitId,
            downSystem.reclaimedWaterUnitId,
            downSystem.technologyType
          )}
      </>
    )
  }

  const getSnoozeIconTooltipTitle = (downSystem: ISonarDownSystem) => {
    return (
      <>
        <div className={classes.tooltip}>
          Reason:{' '}
          {downSystemsTableHelper.getDownSystemSnoozeReasonForTooltip(
            downSystem.snoozeReason
          )}
        </div>
        <div className={classes.tooltip}>
          Snoozed Until:{' '}
          {formatDateObjectToString(
            new Date(downSystem.snoozeEnd as string),
            'YYYY-MM-DD',
            'true'
          )}
        </div>
      </>
    )
  }

  const renderSnoozeIcon = (row: ISonarDownSystem) => {
    return (
      <>
        {row.snoozeId ? (
          <Tooltip title={getSnoozeIconTooltipTitle(row)}>
            <Snooze className={classes.snoozeIcon} />
          </Tooltip>
        ) : null}
      </>
    )
  }

  const renderMenu = (
    snoozeId: number | null,
    remoteUnitId: number | null,
    reclaimedWaterId: number | null,
    technologyType: string
  ) => {
    return (
      clickedDownSystem?.remoteUnitId === remoteUnitId &&
      clickedDownSystem?.reclaimedWaterUnitId === reclaimedWaterId &&
      clickedDownSystem?.technologyType === technologyType && (
        <Menu
          id="snooze-menu"
          keepMounted
          anchorEl={anchorEl}
          open={showSnoozeMenu}
          onClose={handleSnoozeMenuClose}
        >
          <MenuItem
            onClick={handleSnoozeModalOpen} // This is for handling Snooze Menu Item clicks
          >
            {snoozeId ? 'Edit Snoozed Down System' : 'Snooze Down System'}
          </MenuItem>
        </Menu>
      )
    )
  }

  const renderChip = (timeDownInDays: number) => {
    // The number being passed is in days, so parsing to string representation.
    const timeDown = downSystemsTableHelper.convertValueToDaysDown(
      timeDownInDays
    )

    let chipStyle = classes.downSystemLessThan10Days
    switch (timeDown) {
      case '<10 days':
        chipStyle = classes.downSystemLessThan10Days
        break
      case '10-30 days':
        chipStyle = classes.downSystemFrom10to30Days
        break
      case '30-180 days':
        chipStyle = classes.downSystemFrom30to180Days
        break
      case '≥180 days':
        chipStyle = classes.downSystemAbove180Days
        break
      default:
        break
    }

    return <Chip label={timeDown} variant="outlined" className={chipStyle} />
  }

  const getSnoozeRowClass = (classes: Classes, snoozeId: number | null) => {
    return snoozeId ? classes.snoozeRowCell : classes.rowCell
  }

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableContainer>
        <Table aria-label="alarms" size={'small'}>
          {renderTableHeader()}

          <TableBody>
            {downSystemsData.map(row => {
              return (
                <TableRow
                  key={`${row.remoteUnitId}-${row.reclaimedWaterUnitId}-${row.technologyType}`}
                  className={getSnoozeRowClass(classes, row.snoozeId)}
                >
                  <TableCell>{renderSnoozeIcon(row)}</TableCell>
                  <Tooltip
                    title={
                      formatDateObjectToString(
                        new Date(row.lastSeenTimestamp),
                        'MMMM DD, YYYY, HH:mm:ss AMPM'
                      ) as string
                    }
                    PopperProps={{
                      modifiers: {
                        offset: {
                          enabled: true,
                          offset: '60, -70',
                        },
                      },
                    }}
                  >
                    <TableCell className={classes.rowCell}>
                      {formatDateObjectToString(
                        new Date(row.lastSeenTimestamp),
                        'YYYY-MM-DD, H:mm AMPM',
                        sonarFrontendFilters.isUTC?.[0]
                      )}
                    </TableCell>
                  </Tooltip>
                  <TableCell>{row.systemId}</TableCell>
                  <TableCell>
                    {downSystemsTableHelper.convertTechnologyType(
                      row.technologyType
                    )}
                  </TableCell>
                  <TableCell>{row.divisionName}</TableCell>
                  <TableCell>{row.plantName}</TableCell>
                  <TableCell>{row.location}</TableCell>
                  <TableCell>{renderChip(row.daysDown)}</TableCell>
                  <TableCell>
                    {hardwareCanWrite(JWT.roles) && renderActionButton(row)}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <SnoozeDownSystemsModal
          clickedDownSystem={clickedDownSystem}
          setClickedDownSystem={setClickedDownSystem}
          showSnoozeModal={showSnoozeModal}
          setShowSnoozeModal={setShowSnoozeModal}
          setSnoozeSavedFlag={setSnoozeSavedFlag}
          showSnoozeSuccessModal={showSnoozeSuccessModal}
          setShowSnoozeSuccessModal={setShowSnoozeSuccessModal}
        />
      </TableContainer>
      {count > 0 ? (
        <SimpleTablePagination
          rowsPerPageOptions={[10, 20, 30, 40]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      ) : (
        <Alert severity="info">Adjust Filters to View Results</Alert>
      )}
    </Paper>
  )
}

DownSystemsTable.propTypes = {
  downSystemsData: PropTypes.array.isRequired,
  onColumnHeaderClick: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default DownSystemsTable
