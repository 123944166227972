import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import downSystemsHelper from './DownSystemsHelper'
import {
  OVERVIEW_ENDPOINT_ITEMS,
  REFRESH_INTERVAL_OVERVIEW,
} from '../../../Hardware/Sonar/Constants/SonarConstants'
import {
  ISonarOverviewTotalAlarms,
  ISonarOverviewSystemSummary,
  ISonarOverviewAlarmsBreakdown,
  ISonarSystemUptimeVsDowntime,
} from '../../../Common/Logic/Types'
import overviewHelper from './OverviewHelper'
import { atomSonarFilter } from '../../../Common/atoms'
import { iconMap } from './SonarTabHelper'

export const useOverview = () => {
  const iconSrc = iconMap.Overview.defaultIcon
  const sonarFilter = useRecoilValue(atomSonarFilter)
  const [lastRefreshed, setLastRefreshed] = useState<string>(
    downSystemsHelper.formatTime(new Date()) // Initial state set with formatted time
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [totalAlarmsData, setTotalAlarmsData] = useState<
    ISonarOverviewTotalAlarms
  >({
    totalAlarmsCount: 0,
    newAlarmsLast24HoursCount: 0,
    lastMonthDailyAverage: 0,
    lastMonthDailyAverageChangePercent: 0,
    lastQuarterDailyAverage: 0,
    lastQuarterDailyAverageChangePercent: 0,
  })

  const [
    systemSummaryData,
    setSystemSummaryData,
  ] = useState<ISonarOverviewSystemSummary | null>(null)

  const [
    alarmsBreakdownData,
    setAlarmsBreakdownData,
  ] = useState<ISonarOverviewAlarmsBreakdown | null>(null)

  const [systemUptimeVsDowntimeData, setSystemUptimeVsDowntimeData] = useState<
    ISonarSystemUptimeVsDowntime[] | null
  >(null)

  const [allEndpointsCalled, setAllEndpointsCalled] = useState<boolean>(false)

  // @ts-ignore
  OVERVIEW_ENDPOINT_ITEMS.TotalAlarms.setter = setTotalAlarmsData
  // @ts-ignore
  OVERVIEW_ENDPOINT_ITEMS.SystemSummary.setter = setSystemSummaryData
  // @ts-ignore
  OVERVIEW_ENDPOINT_ITEMS.AlarmsBreakdown.setter = setAlarmsBreakdownData
  // @ts-ignore
  OVERVIEW_ENDPOINT_ITEMS.SystemUptimeVsDowntime.setter = setSystemUptimeVsDowntimeData

  // Callbacks
  const retrieveOverviewData = useCallback(
    async (abortController: AbortController) => {
      // Skip API call if required filters are not selected
      if (!sonarFilter.technology?.length) return

      const keys = Object.keys(OVERVIEW_ENDPOINT_ITEMS)
      setIsLoading(true)
      setAllEndpointsCalled(false)

      const fetchPromises = keys.map(async key => {
        try {
          //@ts-ignore
          const item = OVERVIEW_ENDPOINT_ITEMS[key]
          const response = await overviewHelper.fetchOverviewData(
            sonarFilter,
            item.endpoint,
            abortController,
            key
          )
          if (response) item.setter(response)
        } catch (error) {
          if (error instanceof Error && error.name !== 'AbortError') {
            console.error(`Error fetching data for ${key}:`, error)
          }
        }
      })

      try {
        await Promise.all(fetchPromises)
        setLastRefreshed(downSystemsHelper.formatTime(new Date()))
        setAllEndpointsCalled(true)
      } catch (error) {
        console.error('Error in Promise.all:', error)
      }
    },
    [setAllEndpointsCalled, setLastRefreshed, sonarFilter]
  )

  // Effects
  useEffect(() => {
    const initialAbortController = new AbortController()

    // Initial load of data
    retrieveOverviewData(initialAbortController)

    return () => {
      initialAbortController.abort()
    }
  }, [retrieveOverviewData])

  useEffect(() => {
    // Setting up interval for data refresh
    const interval = setInterval(() => {
      const intervalAbortController = new AbortController()
      retrieveOverviewData(intervalAbortController)
    }, REFRESH_INTERVAL_OVERVIEW)

    return () => {
      clearInterval(interval)
    }
  }, [retrieveOverviewData])

  const isDataComplete =
    alarmsBreakdownData &&
    systemUptimeVsDowntimeData &&
    systemSummaryData &&
    totalAlarmsData

  useEffect(() => {
    if (allEndpointsCalled && isDataComplete) setIsLoading(false)
  }, [allEndpointsCalled, isDataComplete])

  return {
    alarmsBreakdownData,
    iconSrc,
    isLoading,
    lastRefreshed,
    systemSummaryData,
    systemUptimeVsDowntimeData,
    totalAlarmsData,
  }
}

const hook = {
  useOverview,
}

export default hook
