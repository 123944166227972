import React from 'react'
import Highcharts, { Options } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Grid, Paper, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import theme from '../../../../theme/muiTheme'
import typography from '../../../../theme/typography'
import { ISonarOverviewAlarmsBreakdown } from '../../../../Common/Logic/Types'
import overviewHelper from '../OverviewHelper'
import { baseColors } from '../../../../theme/colors'
import { useReflowGraphDueToFilterPanel } from '../../../../Common/Hooks/hooks'

const useStyles = makeStyles(() => ({
  //@ts-ignore
  ...theme.customClasses.sonarOverviewVisuals,
}))

interface OverviewTypeOfAlarmsProps {
  chartData: ISonarOverviewAlarmsBreakdown[] | null
  isFilterOpen: boolean
}

export const OverviewTypeOfAlarms = (props: OverviewTypeOfAlarmsProps) => {
  const { chartData, isFilterOpen } = props
  const classes: ClassNameMap<string> = useStyles()

  // Digest the data for pie chart
  const processedData = overviewHelper.processAlarmsBreakdownData(chartData)
  const { chartComponentRef } = useReflowGraphDueToFilterPanel({ isFilterOpen })
  const legendX = window.innerWidth < 1600 ? 30 : 0
  const legendY = window.innerHeight < 1080 ? 50 : 20
  const legendWidth = isFilterOpen ? 150 : 250

  const chartOptions: Options = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Type of Active Alarms',
      align: 'left',
      margin: 32,
      useHTML: true,
      style: {
        fontSize: typography.fontSize.M,
        fontFamily: typography.fontFamily.heading,
      },
    },
    subtitle: {
      text: '<br/>Displaying a distribution of current active alarms by type.',
      align: 'left',
      useHTML: true,
      style: {
        fontSize: typography.fontSize.S,
        color: baseColors.text.primary,
        fontFamily: typography.fontFamily.body,
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        // @SONAR_STOP@
        // Skip sonar scanning as this is HighCharts suggested code functionality
        // @ts-ignore
        const { name, y, count, color } = this.point
        // @SONAR_START@
        return `
          <div style="
            display: flex; 
            align-items: center; 
            padding: 4px 8px; 
            background: white; 
            border: 1px solid #e0e0e0; 
            border-radius: 4px;
            box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.1);
            font-family: ${typography.fontFamily.body};
          ">
            <div>
              <div style="
                font-size: 10px; 
                font-weight: 400; 
                line-height: 16px; 
                letter-spacing: 1px; 
                text-align: left; 
                color: #000; 
                text-transform: uppercase;
              ">
                ${name}
              </div>
              <div style="
                display: flex; 
                align-items: center; 
                font-size: 10px;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: 0.6px;
                text-align: left;
                color: #000;
              ">
                <span style="
                  width: 8px; 
                  height: 8px; 
                  border-radius: 50%; 
                  background-color: ${color}; 
                  margin-right: 8px;
                "></span>
                ${count} Alarms (${y?.toFixed(2)}%)
              </div>
            </div>
          </div>
        `
      },
      backgroundColor: 'transparent',
      borderWidth: 0,
      shadow: false,
    },

    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      width: legendWidth,
      x: legendX,
      y: legendY,
      itemStyle: {
        color: baseColors.text.secondary,
        fontSize: typography.highcharts.fontSize,
        fontFamily: typography.highcharts.fontFamily,
        fontWeight: '500',
      },
      itemMarginBottom: 16,
      useHTML: true,
      labelFormatter: function() {
        // @SONAR_STOP@
        // Skip sonar scanning as this is HighCharts suggested code functionality
        return `<div style="width: 200px; white-space: normal; overflow: visible;">${this.name}</div>`
        // @SONAR_START@
      },
    },
    series: [
      {
        type: 'pie',
        name: 'Alarm Type',
        data: processedData,
      },
    ],
  }

  return (
    <Paper className={classes.paperContainer}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            style={{ margin: '0px', padding: '0px' }}
            ref={chartComponentRef}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
