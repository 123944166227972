import {
  TSonarTechnologyType,
  TSortOrder,
} from '../../../../Common/Logic/Types'
import { SnoozeDownSystemReason } from '../../../Logic/Types'
import { getCommonTableHeadCells } from '../../Helpers/SonarHelpers'

// Get the order direction for the table header
const getOrderDirection = (
  column: string,
  order: TSortOrder,
  orderBy: string
) => {
  return orderBy === column ? order : 'asc'
}

const convertValueToDaysDown = (timeDownInDays: number) => {
  if (timeDownInDays < 10) {
    return '<10 days'
  } else if (timeDownInDays < 30) {
    return '10-30 days'
  } else if (timeDownInDays < 180) {
    return '30-180 days'
  } else {
    return '\u2265180 days' // ≥180 days
  }
}

const convertTechnologyType = (technologyType: string) => {
  switch (technologyType) {
    case 'Plc':
      return 'PLC'
    case 'ReclaimedWater':
      return 'RW'
    default:
      return technologyType as TSonarTechnologyType
  }
}

const getDownsSystemsTableHeadCells = (isUTCBooleanString?: string) => {
  const isUTC = isUTCBooleanString === 'true'
  const additionalCells = [
    {
      align: 'left',
      id: 'daysDown',
      name: 'Time Down',
      sortable: true,
      width: '120px',
    },
  ]
  return getCommonTableHeadCells(
    isUTC,
    'lastSeenTimestamp',
    'Last Seen',
    'Last Seen Sharing Date',
    'technologyType',
    additionalCells
  )
}

const getDownSystemSnoozeReasonForTooltip = (
  reason: string | null | undefined
) => {
  switch (reason) {
    case SnoozeDownSystemReason.Seasonal:
      return 'Seasonal'
    case SnoozeDownSystemReason.ProducerMaintenance:
      return 'Maintenance (Producer)'
    case SnoozeDownSystemReason.CarbonCureMaintenance:
      return 'Maintenance (CarbonCure)'
    case SnoozeDownSystemReason.CO2TankMaintenance:
      return 'Maintenance (CO\u2082 Tank)'
    case SnoozeDownSystemReason.Churned:
      return 'Churned'
    case SnoozeDownSystemReason.Other:
      return 'Other'
    default:
      return null
  }
}

const downSystemsTableHelper = {
  convertTechnologyType,
  convertValueToDaysDown,
  getOrderDirection,
  getDownsSystemsTableHeadCells,
  getDownSystemSnoozeReasonForTooltip,
}

export default downSystemsTableHelper
